import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";

import { Checkbox } from "../../components/Checkbox";

const FiltersModal = (props) => {
    const {
        filtersModal,
        setFiltersModal = () => {},
        filterConstants = {},
        onFilterSelect = () => {},
        currentFilters = []
    } = props;

    const [filters, setFilters] = useState(renderFormattedFilters());

    useEffect(() => {
        if (Boolean(filters.length)) {
            let updated = filters.map((item) => {
                return {
                    ...item,
                    isChecked: currentFilters.includes(item.value) ? true : false
                };
            });

            setFilters(updated);
        }
    }, [filtersModal]);

    function renderFormattedFilters() {
        return Object.values(filterConstants).map((item) => {
            return {
                isChecked: false,
                value: item,
                title: filterConstants.getDisplayTextKey(item)
            };
        });
    }

    const handleFilterChange = (key, value) => {
        let allFilters = [...filters];

        let updatedFilters = allFilters.map((item) => {
            if (item.value === key) {
                return {
                    ...item,
                    isChecked: !value
                };
            }
            return item;
        });

        setFilters(updatedFilters);
    };

    const handleFilterSubmit = () => {
        let valuesExists = filters.some((item) => item.isChecked === true);

        let selected = [];

        if (valuesExists) {
            selected = filters.filter((item) => item.isChecked === true).map((item) => item.value);
        }

        onFilterSelect(selected);
        setFiltersModal(false);
    };

    return (
        <Modal size="sm" isOpen={filtersModal} className="modal-dialog-centered ">
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    Select Filter
                </h5>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setFiltersModal(false)}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                {Boolean(filters.length) &&
                    filters.map((item, index) => {
                        const { isChecked, value, title } = item;
                        return (
                            <Checkbox
                                title={title}
                                key={index}
                                checked={isChecked}
                                id={index}
                                onChange={() => handleFilterChange(value, isChecked)}
                            />
                        );
                    })}
            </div>
            <div className="modal-footer">
                <button
                    type="button"
                    className="btn btn-primary waves-effect"
                    data-dismiss="modal"
                    onClick={handleFilterSubmit}
                >
                    Apply
                </button>
            </div>
        </Modal>
    );
};

export default FiltersModal;
