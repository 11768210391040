import { injectBaseConstantMethods } from "./BaseConstants";

export const UserStatus = {
    UNVERIFIED: 0,
    VERIFIED: 1,
    MATCH_READY: 4,
    COMPLETING_PROFILE: 3,
    SELECTING_PREFERENCES: 5,
    SUSPENDED: 6,
    REMOVED: 7,
    ID_VERIFICATION_PENDING: 8,
    DELETED: 9,
    MANUAL_DELETED: 10
};

const displayTextKeys = {
    [UserStatus.UNVERIFIED]: "Unverified",
    [UserStatus.VERIFIED]: "Verified",
    [UserStatus.MATCH_READY]: "Match Ready",
    [UserStatus.COMPLETING_PROFILE]: "Completing Profile",
    [UserStatus.SELECTING_PREFERENCES]: "Selecting Preferences",
    [UserStatus.SUSPENDED]: "Suspended",
    [UserStatus.REMOVED]: "Removed",
    [UserStatus.ID_VERIFICATION_PENDING]: "Id Verification Pending",
    [UserStatus.DELETED]: "Deleted",
    [UserStatus.MANUAL_DELETED]: "Admin Deleted"
};

const labelClass = {
    [UserStatus.UNVERIFIED]: "warning",
    [UserStatus.VERIFIED]: "success",
    [UserStatus.MATCH_READY]: "success",
    [UserStatus.COMPLETING_PROFILE]: "info",
    [UserStatus.SELECTING_PREFERENCES]: "info",
    [UserStatus.SUSPENDED]: "warning",
    [UserStatus.REMOVED]: "danger",
    [UserStatus.ID_VERIFICATION_PENDING]: "info",
    [UserStatus.DELETED]: "danger",
    [UserStatus.MANUAL_DELETED]: "danger"
};

export default injectBaseConstantMethods(UserStatus, displayTextKeys, labelClass);
