import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import "./userDetails.scss";

import { Loader } from "../../components/Loader";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Accordian } from "../../components/Accordian";
import { CustomBadge } from "../../components/Badge";
import { ListItem } from "../../components/ListItem";
import { getUserById } from "../../store/actions";
import { convertInches, getHourFormatFromMilliSeconds } from "../../utils/commonUtils";
import { dateTimeFormat, convertDateTime } from "../../utils/dateFormatUtils";
import {
    MaritialStatusLabels,
    UserFirkaLabels,
    UserPrayerRoutineLabels,
    UserLivingStatusLabels,
    UserHalalPreferenceLabels
} from "../../constants/Labels";
import {
    UserStatus,
    DeleteReasons,
    //SubscriptionPlanCategory,
    UserSubscriptionType
} from "../../constants";
import { getRoute, APP_ROUTES } from "../../helpers/routeHelpers";

import EmptyProfileImg from "../../assets/empty_profile.jpg";

const TABS = {
    PERSONAL_DETAILS: "Personal Details",
    PROFESSIONAL: "Professional",
    RELIGIOUS: "Religious",
    PERSONALITY_TRAITS: "Personality Traits",
    PREFERENCES: "Preferences",
    SUBSCRIPTION_TYPE: "Subscription Type",
    GUARDIAN_DETAILS: "Guardian Details",
    PROFILE_STATS: "Profile Stats"
};

const UserDetails = (props) => {
    const { getUserById } = props;

    const [userData, setUserData] = useState(null);
    const [currentTab, setCurrentTab] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        getUserById(id)
            .then((res) => {
                setUserData(res.Data);
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });

        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [id, getUserById]);

    const getUserLocation = (userData) => {
        if (
            userData?.PersonalDetails?.Location?.City &&
            userData?.PersonalDetails?.Location?.Country
        ) {
            return `${userData.PersonalDetails.Location.City} , ${userData.PersonalDetails.Location.Country}`;
        }

        return "";
    };

    const getHeightRange = (userData) => {
        if (
            userData?.Preferences?.Personal?.HeightStart &&
            userData?.Preferences?.Personal?.HeightEnd
        ) {
            let heightStart = convertInches(userData.Preferences.Personal.HeightStart);
            let heightEnd = convertInches(userData.Preferences.Personal.HeightEnd);

            return `${heightStart} - ${heightEnd}`;
        }

        return "-";
    };

    const getFirstMatch = () => {
        if (
            userData &&
            userData?.FirstMatch &&
            userData?.FirstMatch?.Matchmaker &&
            userData?.FirstMatch?.Matchmaker?.ProfileReadyAt
        ) {
            let duration =
                userData?.FirstMatch?.CreatedAt - userData?.FirstMatch?.Matchmaker?.ProfileReadyAt;

            return convertDateTime({
                date: duration,
                customFormat: dateTimeFormat.appDateFormat
            });
        }

        return "-";
    };

    const renderPopularUsageTime = () => {
        if (
            userData &&
            userData?.MostPopularUsageTime?.Start &&
            userData?.MostPopularUsageTime?.End
        ) {
            return (
                moment(userData?.MostPopularUsageTime?.Start, "HH:mm").format("hh:mm a") +
                " - " +
                moment(userData?.MostPopularUsageTime?.End, "HH:mm").format("hh:mm a")
            );
        }

        return "N/A";
    };

    const renderDeletedReason = () => {
        if (userData?.Status === UserStatus?.DELETED) {
            return (
                <div className="mt-5">
                    <h6 className="font-size-14">Reason To Leave </h6>
                    <CustomBadge classes="danger mt-1">
                        {DeleteReasons.getDisplayTextKey(userData?.LeavingReason)}
                    </CustomBadge>

                    <div className="mt-3">
                        <h6 className="font-size-14">Remarks :</h6>
                        <p className="">{userData?.ImprovementText || "-"}</p>
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        withBackButton
                        titleLink={getRoute(APP_ROUTES.USERS_LISTING)}
                        title="Users"
                        breadcrumbItem={props.t("User Details")}
                    />

                    <Col xs="12">
                        <Card>
                            <CardBody>
                                {loading ? (
                                    <div className="d-flex flex-column justify-content-center align-items-center vh-90">
                                        <Loader />
                                        <div className="mt-4">Fetching Profile ....</div>
                                    </div>
                                ) : userData ? (
                                    <>
                                        <Row>
                                            <Col
                                                xs="6"
                                                // className="d-flex justify-content-center flex-column align-items-center"
                                            >
                                                <div className="user-detail-avatar d-flex justify-content-center rounded-circle text-center mb-3">
                                                    <img
                                                        className="avatar-xl rounded-circle img-thumbnail"
                                                        src={
                                                            userData?.ProfilePictureUrl ||
                                                            EmptyProfileImg
                                                        }
                                                        alt="userIcon"
                                                    />
                                                </div>
                                                <div className="d-flex flex-column align-items-center">
                                                    <h5 className="font-size-15 mt-2">
                                                        {userData?.Name}
                                                    </h5>
                                                    <p className="text-muted mb-0 ">
                                                        {userData?.Email}
                                                    </p>
                                                    <p className="text-muted mb-0 ">
                                                        Age : {userData?.Age}
                                                    </p>
                                                </div>
                                                {/* <Col xs="6"> */}
                                                <div className="">
                                                    <h5 className="font-size-15 mt-4">About</h5>
                                                    <div>
                                                        <p className="text-muted mb-0">
                                                            {userData?.About}
                                                        </p>
                                                    </div>
                                                </div>
                                                {renderDeletedReason()}

                                                {/* </Col> */}
                                            </Col>

                                            <Col xs="6">
                                                <Accordian
                                                    isOpen={currentTab === TABS.PERSONAL_DETAILS}
                                                    title={"Personal Details"}
                                                    onClick={() =>
                                                        setCurrentTab(
                                                            currentTab === TABS.PERSONAL_DETAILS
                                                                ? null
                                                                : TABS.PERSONAL_DETAILS
                                                        )
                                                    }
                                                >
                                                    <Row>
                                                        <Col xs={6}>
                                                            <ListItem
                                                                label="Height"
                                                                value={convertInches(
                                                                    userData?.PersonalDetails
                                                                        ?.Height
                                                                )}
                                                            />
                                                            <ListItem
                                                                label="Current Location"
                                                                value={getUserLocation(userData)}
                                                            />
                                                            <ListItem
                                                                label="Languages"
                                                                value={
                                                                    userData?.PersonalDetails &&
                                                                    userData.PersonalDetails
                                                                        .Languages.length &&
                                                                    userData.PersonalDetails.Languages.toString()
                                                                }
                                                            />
                                                        </Col>
                                                        <Col xs={6}>
                                                            <ListItem
                                                                label="Maritial Status"
                                                                value={
                                                                    MaritialStatusLabels[
                                                                        userData?.PersonalDetails
                                                                            ?.MaritalStatus
                                                                    ]
                                                                }
                                                            />
                                                            <ListItem
                                                                label="Children"
                                                                value={
                                                                    userData?.PersonalDetails
                                                                        ?.Children > 2
                                                                        ? "More than 2"
                                                                        : userData?.PersonalDetails
                                                                              ?.Children
                                                                }
                                                            />
                                                            <ListItem
                                                                label="Current Living Situation"
                                                                value={
                                                                    UserLivingStatusLabels[
                                                                        userData?.PersonalDetails
                                                                            ?.CurrentLivingSituation
                                                                    ]
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Accordian>
                                                <Accordian
                                                    isOpen={currentTab === TABS.PROFESSIONAL}
                                                    onClick={() =>
                                                        setCurrentTab(
                                                            currentTab === TABS.PROFESSIONAL
                                                                ? null
                                                                : TABS.PROFESSIONAL
                                                        )
                                                    }
                                                    title={"Professional"}
                                                    containerClasses="mt-3"
                                                >
                                                    <Row>
                                                        <Col xs={12}>
                                                            <ListItem
                                                                label="Occupation"
                                                                value={
                                                                    userData?.ProfessionalDetials
                                                                        ?.Occupation
                                                                }
                                                            />
                                                            <ListItem
                                                                label="Level Of Education"
                                                                value={
                                                                    userData?.ProfessionalDetials
                                                                        ?.EducationLevel
                                                                }
                                                            />
                                                            <ListItem
                                                                label="Working Situation"
                                                                value={
                                                                    userData?.ProfessionalDetials
                                                                        ?.WorkingSituation
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Accordian>

                                                <Accordian
                                                    isOpen={currentTab === TABS.RELIGIOUS}
                                                    onClick={() =>
                                                        setCurrentTab(
                                                            currentTab === TABS.RELIGIOUS
                                                                ? null
                                                                : TABS.RELIGIOUS
                                                        )
                                                    }
                                                    title={"Religious"}
                                                    containerClasses="mt-3"
                                                >
                                                    <Row>
                                                        <Col xs={6}>
                                                            <ListItem
                                                                label="Sect"
                                                                value={
                                                                    UserFirkaLabels[
                                                                        userData?.ReligiousDetails
                                                                            ?.Sect
                                                                    ]
                                                                }
                                                            />
                                                            <ListItem
                                                                label="Prayers Routine"
                                                                value={
                                                                    UserPrayerRoutineLabels[
                                                                        userData?.ReligiousDetails
                                                                            ?.PrayerRoutine
                                                                    ]
                                                                }
                                                            />
                                                            <ListItem
                                                                label="Halal Preference"
                                                                value={
                                                                    UserHalalPreferenceLabels[
                                                                        userData?.ReligiousDetails
                                                                            ?.HalalPreference
                                                                    ]
                                                                }
                                                            />
                                                        </Col>
                                                        <Col xs={6}>
                                                            <ListItem
                                                                label="Alcohol Consumption"
                                                                value={
                                                                    userData?.ReligiousDetails
                                                                        ?.AlcoholConsumption
                                                                        ? "Yes"
                                                                        : "No"
                                                                }
                                                            />
                                                            <ListItem
                                                                label="Smoking"
                                                                value={
                                                                    userData?.ReligiousDetails
                                                                        ?.Smoking
                                                                        ? "Yes"
                                                                        : "No"
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Accordian>
                                                {!!userData?.Traits.length && (
                                                    <Accordian
                                                        isOpen={
                                                            currentTab === TABS.PERSONALITY_TRAITS
                                                        }
                                                        onClick={() =>
                                                            setCurrentTab(
                                                                currentTab ===
                                                                    TABS.PERSONALITY_TRAITS
                                                                    ? null
                                                                    : TABS.PERSONALITY_TRAITS
                                                            )
                                                        }
                                                        title={"Personality Traits"}
                                                        containerClasses="mt-3"
                                                    >
                                                        <Row>
                                                            <Col xs="6">
                                                                {userData?.Traits.map(
                                                                    (item, index) => {
                                                                        return (
                                                                            <CustomBadge
                                                                                key={index}
                                                                                classes={`success ml-2`}
                                                                                color={"success"}
                                                                            >
                                                                                {item}
                                                                            </CustomBadge>
                                                                        );
                                                                    }
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Accordian>
                                                )}
                                                <Accordian
                                                    isOpen={currentTab === TABS.PREFERENCES}
                                                    onClick={() =>
                                                        setCurrentTab(
                                                            currentTab === TABS.PREFERENCES
                                                                ? null
                                                                : TABS.PREFERENCES
                                                        )
                                                    }
                                                    title={"Preferences"}
                                                    containerClasses="mt-3"
                                                >
                                                    <h5 className="font-size-7">Personal</h5>

                                                    <div className="mt-3">
                                                        <ListItem
                                                            label="Age Range "
                                                            value={`${props.t(
                                                                userData?.Preferences?.Personal
                                                                    ?.AgeStart
                                                            )} - ${props.t(
                                                                userData?.Preferences?.Personal
                                                                    ?.AgeEnd
                                                            )}`}
                                                            valueClasses="font-weight-light"
                                                        />
                                                        <ListItem
                                                            label="Height Range"
                                                            value={getHeightRange(userData)}
                                                            valueClasses="font-weight-light"
                                                        />
                                                        <ListItem
                                                            label="Preferred If Partner Has Children From Previous Relationship"
                                                            value={props.t(
                                                                userData?.Preferences?.Personal
                                                                    ?.PreviousChildren
                                                                    ? "Yes"
                                                                    : "No"
                                                            )}
                                                            valueClasses="font-weight-light"
                                                        />
                                                        <ListItem
                                                            label="Preferred Partner Previous Maritial Status"
                                                            value={props.t(
                                                                userData?.Preferences?.Personal
                                                                    ?.PreviousMaritalStatus
                                                                    ? "Married"
                                                                    : "Not Married"
                                                            )}
                                                            valueClasses="font-weight-light"
                                                        />
                                                        <ListItem
                                                            label="Family Plans"
                                                            value={props.t(
                                                                userData?.Preferences?.Personal
                                                                    ?.FamilyPlans
                                                                    ? "Open to children"
                                                                    : "Not open to children"
                                                            )}
                                                            valueClasses="font-weight-light"
                                                        />
                                                        <ListItem
                                                            label="Preferred If Partner is Widowed"
                                                            value={props.t(
                                                                userData?.Preferences?.Personal
                                                                    ?.IsWidowed
                                                                    ? "Yes"
                                                                    : "No"
                                                            )}
                                                            valueClasses="font-weight-light"
                                                        />
                                                        <ListItem
                                                            label="Preferred If Partner is Divorced"
                                                            value={props.t(
                                                                userData?.Preferences?.Personal
                                                                    ?.IsDivorced
                                                                    ? "Yes"
                                                                    : "No"
                                                            )}
                                                            valueClasses="font-weight-light"
                                                        />
                                                    </div>

                                                    <h5 className="font-size-7 mt-3">
                                                        Professional
                                                    </h5>

                                                    <div className="mt-3">
                                                        <ListItem
                                                            label="Preferred If Partner Is Working"
                                                            value={props.t(
                                                                userData?.Preferences?.Professional
                                                                    ?.Working
                                                                    ? "Yes"
                                                                    : "No"
                                                            )}
                                                            valueClasses="font-weight-light"
                                                        />
                                                        <ListItem
                                                            label="Preferred If Partner Stays At home"
                                                            value={
                                                                userData?.Preferences?.Professional
                                                                    ?.StayAtHome
                                                                    ? "Yes"
                                                                    : "No"
                                                            }
                                                            valueClasses="font-weight-light"
                                                        />
                                                    </div>

                                                    <h5 className="font-size-7 mt-3">Religious</h5>

                                                    <div>
                                                        <ListItem
                                                            label="Sect"
                                                            value={
                                                                !userData?.Preferences?.Religious
                                                                    ?.Sect
                                                                    ? "Open to different sect"
                                                                    : UserFirkaLabels[
                                                                          userData?.Preferences
                                                                              ?.Religious?.Sect
                                                                      ]
                                                            }
                                                            valueClasses="font-weight-light"
                                                        />

                                                        <ListItem
                                                            label="Prayer Routine"
                                                            value={
                                                                userData?.Preferences?.Religious
                                                                    ?.PrayerRoutine
                                                                    ? "Strict"
                                                                    : "-"
                                                            }
                                                            valueClasses="font-weight-light"
                                                        />

                                                        <ListItem
                                                            label="Halal Preference"
                                                            value={
                                                                UserHalalPreferenceLabels[
                                                                    userData?.Preferences?.Religious
                                                                        ?.HalalPreference
                                                                ]
                                                            }
                                                            valueClasses="font-weight-light"
                                                        />

                                                        <ListItem
                                                            label="Alcohol Consumption"
                                                            value={
                                                                userData?.Preferences?.Religious
                                                                    ?.AlcoholConsumption
                                                                    ? "Yes"
                                                                    : "No"
                                                            }
                                                            valueClasses="font-weight-light"
                                                        />

                                                        <ListItem
                                                            label="Smoking"
                                                            value={
                                                                userData?.Preferences?.Religious
                                                                    ?.Smoking
                                                                    ? "Yes"
                                                                    : "No"
                                                            }
                                                            valueClasses="font-weight-light"
                                                        />
                                                    </div>

                                                    <h5 className="font-size-7 mt-3">
                                                        Personality
                                                    </h5>

                                                    <h6 className="font-size-7 mt-3">Values</h6>

                                                    {!!userData?.Preferences?.Personality?.Values
                                                        .length ? (
                                                        userData?.Preferences?.Personality?.Values.map(
                                                            (item, index) => {
                                                                return (
                                                                    <CustomBadge
                                                                        key={index}
                                                                        classes="success ml-2"
                                                                        color={"success"}
                                                                    >
                                                                        {item}
                                                                    </CustomBadge>
                                                                );
                                                            }
                                                        )
                                                    ) : (
                                                        <div className="ml-1">-</div>
                                                    )}
                                                    <h6 className="font-size-7 mt-3">Traits</h6>

                                                    {!!userData?.Preferences?.Personality?.Traits
                                                        .length ? (
                                                        userData?.Preferences?.Personality?.Traits.map(
                                                            (item, index) => {
                                                                return (
                                                                    <CustomBadge
                                                                        key={index}
                                                                        classes="success ml-2"
                                                                        color={"success"}
                                                                    >
                                                                        {item}
                                                                    </CustomBadge>
                                                                );
                                                            }
                                                        )
                                                    ) : (
                                                        <div className="ml-1">-</div>
                                                    )}

                                                    <div></div>
                                                </Accordian>

                                                {userData?.Guardian && (
                                                    <Accordian
                                                        isOpen={
                                                            currentTab === TABS.GUARDIAN_DETAILS
                                                        }
                                                        onClick={() =>
                                                            setCurrentTab(
                                                                currentTab === TABS.GUARDIAN_DETAILS
                                                                    ? null
                                                                    : TABS.GUARDIAN_DETAILS
                                                            )
                                                        }
                                                        title={"Guardian Details"}
                                                        containerClasses="mt-3"
                                                    >
                                                        <ListItem
                                                            label="Guardian Name"
                                                            value={userData?.Guardian?.FullName}
                                                            valueClasses="font-weight-light"
                                                        />
                                                        <ListItem
                                                            label="Guardian Email"
                                                            value={userData?.Guardian?.Email}
                                                            valueClasses="font-weight-light"
                                                        />
                                                        <ListItem
                                                            label="Is Verified"
                                                            value={
                                                                userData?.Guardian?.IsVerified
                                                                    ? "Verified"
                                                                    : "Not Verified"
                                                            }
                                                            valueClasses="font-weight-light"
                                                        />
                                                    </Accordian>
                                                )}

                                                <Accordian
                                                    isOpen={currentTab === TABS.SUBSCRIPTION_TYPE}
                                                    onClick={() =>
                                                        setCurrentTab(
                                                            currentTab === TABS.SUBSCRIPTION_TYPE
                                                                ? null
                                                                : TABS.SUBSCRIPTION_TYPE
                                                        )
                                                    }
                                                    title={"Subscription Type"}
                                                    containerClasses="mt-3"
                                                >
                                                    <div>
                                                        Type :{" "}
                                                        <CustomBadge
                                                            color={UserSubscriptionType.getLabelClass(
                                                                userData.UserType
                                                            )}
                                                            classes={UserSubscriptionType.getLabelClass(
                                                                userData.UserType
                                                            )}
                                                        >
                                                            {UserSubscriptionType.getDisplayTextKey(
                                                                userData.UserType
                                                            )}
                                                        </CustomBadge>
                                                    </div>

                                                    {/* {userData?.UserSubscription ? (
                                                        <>
                                                            <div>
                                                                <span>Name :</span>{" "}
                                                                <span>
                                                                    {
                                                                        userData?.UserSubscription
                                                                            ?.Plan?.Name
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <span>Amount :</span>{" "}
                                                                <span>
                                                                    {
                                                                        userData?.UserSubscription
                                                                            ?.Plan?.Amount
                                                                    }{" "}
                                                                    {
                                                                        userData?.UserSubscription
                                                                            ?.Plan?.Currency
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div>
                                                                Status :
                                                                <CustomBadge
                                                                    classes={`success ml-1`}
                                                                    color={`success`}
                                                                >
                                                                    {"Paid"}
                                                                </CustomBadge>
                                                            </div>
                                                            <div className="mt-1">
                                                                Type :
                                                                <CustomBadge
                                                                    classes={`success ml-1`}
                                                                    color={`success`}
                                                                >
                                                                    {SubscriptionPlanCategory.getDisplayTextKey(
                                                                        userData?.UserSubscription
                                                                            ?.Plan?.Category
                                                                    )}
                                                                </CustomBadge>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <div>No Subscription Availed</div>
                                                    )} */}
                                                </Accordian>
                                                <Accordian
                                                    isOpen={currentTab === TABS.PROFILE_STATS}
                                                    title={"Profile Stats"}
                                                    containerClasses="mt-3"
                                                    onClick={() =>
                                                        setCurrentTab(
                                                            currentTab === TABS.PROFILE_STATS
                                                                ? null
                                                                : TABS.PROFILE_STATS
                                                        )
                                                    }
                                                >
                                                    <Row>
                                                        <Col xs={12}>
                                                            <ListItem
                                                                label="Popular Usage Time"
                                                                value={renderPopularUsageTime()}
                                                            />
                                                            <ListItem
                                                                label="Total Usage Time"
                                                                value={getHourFormatFromMilliSeconds(
                                                                    userData?.TotalApplicationUsageTimeMS
                                                                )}
                                                            />
                                                            <ListItem
                                                                label="Profile Ready At"
                                                                value={
                                                                    (userData &&
                                                                        userData?.ProfileReadyAt &&
                                                                        convertDateTime({
                                                                            date:
                                                                                userData?.ProfileReadyAt,
                                                                            customFormat:
                                                                                dateTimeFormat.appDateFormat
                                                                        })) ||
                                                                    "-"
                                                                }
                                                            />

                                                            <ListItem
                                                                label="First Match"
                                                                value={getFirstMatch()}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Accordian>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <div className="d-flex flex-column justify-content-center align-items-center vh-90">
                                        <div className="mt-4">No Data Available</div>
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = {
    getUserById
};

export default connect(null, mapDispatchToProps)(UserDetails);
