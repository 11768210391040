import { injectBaseConstantMethods } from "./BaseConstants";

export const UserDeleteReasons = {
    FOUND_FUTURE_PARTNER: 1,
    MATCH_NOT_FOUND: 2,
    EXPECTING_DIFFERENT: 3,
    UNLIKE_PLATFORM: 4,
    OTHER: 5,
    NONE: 6
};

const displayTextKeys = {
    [UserDeleteReasons.FOUND_FUTURE_PARTNER]: "Found Future Partner",
    [UserDeleteReasons.MATCH_NOT_FOUND]: "Match Not Found",
    [UserDeleteReasons.EXPECTING_DIFFERENT]: "Expecting Different",
    [UserDeleteReasons.UNLIKE_PLATFORM]: "Unlike Platform",
    [UserDeleteReasons.OTHER]: "Other",
    [UserDeleteReasons.NONE]: "None"
};

const labelClass = {
    [UserDeleteReasons.FOUND_FUTURE_PARTNER]: "danger",
    [UserDeleteReasons.MATCH_NOT_FOUND]: "danger",
    [UserDeleteReasons.EXPECTING_DIFFERENT]: "danger",
    [UserDeleteReasons.UNLIKE_PLATFORM]: "danger",
    [UserDeleteReasons.OTHER]: "danger",
    [UserDeleteReasons.NONE]: "danger"
};

export default injectBaseConstantMethods(UserDeleteReasons, displayTextKeys, labelClass);
