import Config from "../../Config";
import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";

export const TRANSACTION_LISTING_REQUEST = "TRANSACTION_LISTING_REQUEST";
export const TRANSACTION_LISTING_SUCCESS = "TRANSACTION_LISTING_SUCCESS";
export const TRANSACTION_LISTING_FAILURE = "TRANSACTION_LISTING_FAILURE";

export const getTransactionListing = (params = {}, abortSignal = null) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    dispatch({ type: TRANSACTION_LISTING_REQUEST });

    return fetchUtil({
        url: appendQueryParams("/transaction", { Limit: Config.LIMIT, ...params }),
        token,
        ...(abortSignal && { abortSignal })
    })
        .then((res) => {
            if (res?.Data && res.Data.Transactions) {
                dispatch({
                    type: TRANSACTION_LISTING_SUCCESS,
                    payload: res.Data.Transactions,
                    totalTransactions: res.Data.TotalTransactions
                });
                return Promise.resolve(res.Data);
            }
            return Promise.reject(false);
        })
        .catch((err) => {
            dispatch({ type: TRANSACTION_LISTING_FAILURE });
            return Promise.reject(err);
        });
};
