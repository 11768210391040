import Config from "../../Config";
import { fetchUtil } from "../../utils/fetchUtil";

export const ANALYTICS_DATA_REQUEST = "ANALYTICS_DATA_REQUEST";
export const ANALYTICS_DATA_SUCCESS = "ANALYTICS_DATA_SUCCESS";
export const ANALYTICS_DATA_FAIL = "ANALYTICS_DATA_FAIL";

export const getAnalyticsData = (type) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    dispatch({ type: ANALYTICS_DATA_REQUEST, analyticsType: type });

    return fetchUtil({
        url: `/analytics/${type}/count`,
        token
    })
        .then((res) => {
            if (res && res.Data) {
                dispatch({
                    type: ANALYTICS_DATA_SUCCESS,
                    payload: res.Data,
                    analyticsType: type
                });
                return Promise.resolve(res.Data);
            }
            return Promise.reject(null);
        })
        .catch((err) => {
            dispatch({ type: ANALYTICS_DATA_FAIL, analyticsType: type });
            return Promise.reject(err);
        });
};

export const getUsersAnalytics = (bodyData, abortSignal = null) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/analytics/users",
        body: JSON.stringify(bodyData),
        method: "POST",
        token,
        abortSignal
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            return Promise.reject(err);
        });
};

export const getDeletedAnalytics = (bodyData, abortSignal = null) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/analytics/deleted-users",
        body: JSON.stringify(bodyData),
        method: "POST",
        token,
        abortSignal
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            return Promise.reject(err);
        });
};

export const getReportsAnalytics = (bodyData, abortSignal = null) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/analytics/reports",
        body: JSON.stringify(bodyData),
        method: "POST",
        token,
        abortSignal
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            return Promise.reject(err);
        });
};

export const getMatchesAnalytics = (bodyData, abortSignal = null) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/analytics/matches",
        body: JSON.stringify(bodyData),
        method: "POST",
        token,
        abortSignal
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            return Promise.reject(err);
        });
};

export const getRevenueAnalytics = (bodyData, abortSignal = null) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/analytics/revenues",
        body: JSON.stringify(bodyData),
        method: "POST",
        token,
        abortSignal
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            return Promise.reject(err);
        });
};

export const getInquiryAnalytics = (bodyData, abortSignal = null) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/analytics/inquiries",
        body: JSON.stringify(bodyData),
        method: "POST",
        token,
        abortSignal
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return;
            }
            return Promise.reject(err);
        });
};

export const getAgeRange = () => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: "/common/age-range",
        token
    })
        .then((res) => {
            return Promise.resolve(res.Data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const downloadCSV = (apiUrl, bodyData) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;
    const url = `${Config.env().API_URL}${apiUrl}`;
    return fetch(url, {
        headers: {
            Authorization: token,
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(bodyData)
    })
        .then((res) => res.text())
        .then((res) => Promise.resolve(res))
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const downloadPDF = (apiUrl, payload) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    const url = `${Config.env().API_URL}${apiUrl}`;
    return fetch(url, {
        headers: {
            Authorization: token,
            "Content-Type": "application/json"
        },
        responseType: "blob",
        method: "POST",
        body: JSON.stringify(payload)
    })
        .then((response) => response.blob())
        .then((res) => Promise.resolve(res))
        .catch((err) => {
            return Promise.reject(err);
        });
};
