import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { withNamespaces } from "react-i18next";

import "./DataTable.scss";

const FilterCell = (props) => {
    const { options, t } = props;
    const { title, filterValue, filterConstants, onFilterSelect, scrollFilter = false } = options;
    const [open, setOpen] = useState(false);

    const handleMenuToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleItemSelect = (value) => {
        onFilterSelect(value);
    };

    return (
        <Dropdown isOpen={open} toggle={handleMenuToggle}>
            <DropdownToggle tag="div" className="text-muted data-table-head sortable ">
                {title}
                {filterValue && (
                    <span className="filter-value">
                        ({t(filterConstants.getDisplayTextKey(filterValue))})
                    </span>
                )}
                <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu className={scrollFilter ? `scroll-dropdown` : ""}>
                <DropdownItem onClick={() => handleItemSelect(null)} active={!filterValue}>
                    {t("All")}
                </DropdownItem>
                {Object.keys(filterConstants).map((key, index) => {
                    return (
                        <DropdownItem
                            key={index}
                            onClick={() => handleItemSelect(filterConstants[key])}
                            active={filterValue === filterConstants[key]}
                        >
                            {t(filterConstants.getDisplayTextKey(filterConstants[key]))}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};

export default withNamespaces()(FilterCell);
