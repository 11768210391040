import React from "react";
import moment from "moment";

import "./ReportDetails.scss";

import { ChatMessageType, GenderType } from "../../constants";
import { getMediaThumbPath } from "../../utils/commonUtils";

const RecordedChat = ({ messages, participants }) => {
    const renderDate = (msg, index) => {
        let previousMessage = messages[index - 1];
        let createdAt = msg.SendDate || new Date();
        let showDate = false;
        let date;
        let isPreviousDay;

        if (!previousMessage) {
            showDate = true;
            date = moment.utc(createdAt).local().format("LLL");
        } else {
            isPreviousDay = moment(previousMessage.SendDate).isBefore(createdAt, "day");

            if (isPreviousDay) {
                showDate = true;
                date = moment.utc(createdAt).local().format("LL");
            }
        }

        return showDate && <p className="text-center">{date}</p>;
    };

    const renderUserMessage = (data, index) => {
        return (
            <>
                <div key={index} className="pl-3 pr-3 user-msg-container d-flex">
                    <div className="p-2 user-reply-container float-left user-reply">
                        <div className="d-flex align-items-center ">
                            <div>
                                <img
                                    className="rounded-circle avatar-sm avatar-container"
                                    src={getMediaThumbPath(data?.Media)}
                                    alt=""
                                />
                            </div>
                            <div className="ml-2">
                                <p className="font-weight-bold">{data?.SenderName}</p>
                                <p className="msg-max-width">{data?.Content || "..."}</p>
                            </div>
                        </div>
                        <p className="ml-2 mt-1">
                            {moment.utc(data.SendDate).local().format("hh:mm A")}
                        </p>
                    </div>
                </div>
            </>
        );
    };

    const renderMessage = (data, index) => {
        if (ChatMessageType.USER_MESSAGE === data.Type) {
            return renderUserMessage(data, index);
        } else if (ChatMessageType.CHAT_ACCEPTED === data.Type) {
            return (
                <p className="text-center font-italic system-msg-color">
                    Chat Request has been accepted
                </p>
            );
        } else if (ChatMessageType.CHAT_REJECTED === data.Type) {
            return (
                <p className="text-center font-italic system-msg-color">{`${data.SenderName} declined the chat.`}</p>
            );
        } else if (ChatMessageType.GUARDIAN_ADDED === data.Type) {
            let userData = participants?.find((user) => user.UserId === data.SenderId);
            return (
                <p className="text-center font-italic system-msg-color">{`${userData?.Name} added ${
                    userData?.Gender === GenderType.MALE ? "his " : "her "
                } Guardian`}</p>
            );
        } else if (ChatMessageType.GUARDIAN_REMOVED === data.Type) {
            let userData = participants?.find((user) => user.UserId === data.SenderId);
            return (
                <p className="text-center font-italic system-msg-color">{`${
                    userData?.Name
                } removed ${userData?.Gender === GenderType.MALE ? "his" : "her"} Guardian`}</p>
            );
        } else if (ChatMessageType.USER_LEFT === data.Type) {
            return (
                <p className="text-center font-italic system-msg-color">{`${data?.SenderName} left the conversation`}</p>
            );
        } else if (ChatMessageType.GUARDIAN_LEFT === data.Type) {
            return (
                <p className="text-center font-italic system-msg-color">{`${data?.SenderName}'s guardian left the conversation`}</p>
            );
        }
    };

    return messages.map((message, index) => {
        return (
            <div key={index}>
                {renderDate(message, index)}
                {renderMessage(message, index)}
            </div>
        );
    });
};

export default RecordedChat;
