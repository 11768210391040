import moment from "moment";
import { AnalyticsDateRangeTypes } from "../constants";

export const getCommaSeparatedNumber = (amount) => {
    return new Intl.NumberFormat("en-US").format(amount);
};

export const getFormattedAmountWithCurrency = (amount, currency = "GBP") => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0
    });
    return formatter.format(amount);
};

export const escapeHTMLElementsFromMessage = (message) => {
    //todo places in util helper
    var htmlEscapes = {
        "<": "&lt;",
        ">": "&gt;"
    };
    // Regex containing the keys listed immediately above.
    var htmlEscaper = /[<>]/g;
    // Escape a string for HTML interpolation.
    return message.replace(htmlEscaper, function (match) {
        return htmlEscapes[match];
    });
};

export const getObjectByKeys = (arr, key = "Id", deleteKey = null) => {
    let obj = {};
    arr.forEach((val) => {
        obj[val[key]] = val;
        if (deleteKey) {
            delete obj[val[key]][deleteKey];
        }
    });
    return obj;
};

export const objectContainsKey = (object, key) => {
    return typeof object === "object" && object && object[key] !== undefined;
};

export const getIdsFromData = (data, key = "id") => {
    return data.map((item) => item[key]);
};

export const convertInches = (inches) => {
    if (inches) {
        let feetFromInches = Math.floor(inches / 12);
        let inchesRemainder = inches % 12;

        return feetFromInches + "ft " + inchesRemainder + '"';
    }
    return "";
};

export const getMediaThumbPath = (mediaObj) => {
    if (mediaObj && Object.keys(mediaObj).length) {
        return mediaObj.ThumbPath;
    }
    return null;
};

export const formatGraphLabels = (dates, type) => {
    if (type === AnalyticsDateRangeTypes.MONTHLY) {
        return dates.map((date) => moment(date, "YYYY-MM").format("MMM"));
    }
    return dates;
};

export const getHourFormatFromMilliSeconds = (millisec) => {
    var seconds = (millisec / 1000).toFixed(0);
    var minutes = Math.floor(Number(seconds) / 60).toString();
    let hours = 0;
    if (Number(minutes) > 59) {
        hours = Math.floor(Number(minutes) / 60);
        // hours = hours >= 10 ? hours : "0" + hours;
        minutes = (Number(minutes) - hours * 60).toString();
        // minutes = Number(minutes) >= 10 ? minutes : "0" + minutes;
    }

    seconds = Math.floor(Number(seconds) % 60).toString();
    // seconds = Number(seconds) >= 10 ? seconds : "0" + seconds;
    return hours + "h " + minutes + "mins " + seconds + "sec ";
};

export const getKeyByValue = (obj, value) => {
    return Object.keys(obj).find((key) => obj[key] === value);
};
