export const APP_ROUTES = {
    DASHBOARD: "/dashboard",
    PAYMENT_LISTING: "/dashboard/payment-listing",
    CREATE_PACKAGE: "/dashboard/payment-listing/new",
    UPDATE_PACKAGE: "/dashboard/payment-listing/:id",
    CUSTOMER_QUERIES: "/dashboard/customer-queries",
    CUSTOMER_QUERY_FEEDBACK: "/dashboard/customer-query/:id",
    REPORT_LISTING: "/dashboard/report-listing",
    USERS_LISTING: "/dashboard/user-listing",
    GUARDIAN_LISTING: "/dashboard/guardian-listing",
    MATCH_MAKER_LISITNG: "/dashboard/matchmaker-listing",
    CREATE_USER: "/dashboard/create/:type",
    UPDATE_USER: "/dashboard/update/:type/:id",
    REPORT_DETAILS: "/dashboard/report-listing/:id",
    USER_DETAILS: "/dashboard/user-details/:id",
    DASHBOARD_FILTERS: "/dashboard/:type/filter",
    TRANSACTION_LISTING: "/dashboard/transactions"
};

export const getRoute = (route, obj = {}) => {
    if (Object.keys(obj).length) {
        let objectKeys = Object.keys(obj);
        objectKeys.forEach((item) => {
            route = route.replace(new RegExp(/:([\d\w])+/, "i"), (match) => {
                let formattedMatchedValue = match.slice(1);
                return obj[formattedMatchedValue];
            });
        });
        return route;
    }
    return route;
};
