import { APP_ROUTES } from "./helpers/routeHelpers";
import { withTitle } from "./hoc";
import { PageTitles } from "./constants";
import Dashboard from "./pages/Dashboard/Dashboard";
//import PaymentPlanListing from "./pages/PaymentPlan/PaymentPlanListing";
import PaymentPlanForm from "./pages/PaymentPlan/PaymentPlanForm";
import CustomerQueryListing from "./pages/CustomerQuery/CustomerQueryListing";
import CustomerQueryFeedback from "./pages/CustomerQueryFeedback/CustomerQueryFeedback";
import ReportListing from "./pages/ReportListing/ReportListing";
import Users from "./pages/Users/Users";
import Guardians from "./pages/Guardians/Guardians";
import MatchMakers from "./pages/MatchMakers/MatchMakers";
import CreateUser from "./pages/CreateUser/CreateUser";
import ReportDetails from "./pages/ReportDetails/ReportDetails";
import UserDetails from "./pages/UserDetails/UserDetails";
import DashboardFilter from "./pages/DashboardFilter/DashboardFilter";
import TransactionsListing from "./pages/TransactionsListing/TransactionsListing";

const routes = [
    {
        path: APP_ROUTES.DASHBOARD,
        name: "Dashboard",
        exact: true,
        component: withTitle({ component: Dashboard, title: PageTitles.DASHBOARD })
    },
    // {
    //     path: APP_ROUTES.PAYMENT_LISTING,
    //     name: "Payment Listing",
    //     exact: true,
    //     component: withTitle({ component: PaymentPlanListing, title: PageTitles.PAYMENT_PLANS })
    // },
    {
        path: APP_ROUTES.TRANSACTION_LISTING,
        name: "Transactions",
        exact: true,
        component: withTitle({ component: TransactionsListing, title: PageTitles.TRANSACTIONS })
    },

    {
        path: APP_ROUTES.CREATE_PACKAGE,
        name: "Create Package",
        exact: true,
        component: withTitle({ component: PaymentPlanForm, title: PageTitles.CREATE_PACKAGE })
    },
    {
        path: APP_ROUTES.USERS_LISTING,
        name: "User Listing",
        exact: false,
        component: withTitle({ component: Users, title: PageTitles.USER_LISTING })
    },

    {
        path: APP_ROUTES.UPDATE_PACKAGE,
        name: "Update Package",
        exact: true,
        component: withTitle({ component: PaymentPlanForm, title: PageTitles.UPDATE_PACKAGE })
    },
    {
        path: APP_ROUTES.CUSTOMER_QUERIES,
        name: "Customer Query",
        exact: true,
        component: withTitle({ component: CustomerQueryListing, title: PageTitles.CUSTOMER_QUERY })
    },
    {
        path: APP_ROUTES.CUSTOMER_QUERY_FEEDBACK,
        name: "Customer Query Feedback",
        exact: true,
        component: withTitle({
            component: CustomerQueryFeedback,
            title: PageTitles.CUSTOMER_QUERY_FEEDBACK
        })
    },
    {
        path: APP_ROUTES.REPORT_LISTING,
        name: "Report listing",
        exact: true,
        component: withTitle({
            component: ReportListing,
            title: PageTitles.USER_REPORTING
        })
    },
    {
        path: APP_ROUTES.GUARDIAN_LISTING,
        name: "Guardians",
        exact: false,
        component: withTitle({ component: Guardians, title: PageTitles.GUARDIAN_LISTING })
    },
    {
        path: APP_ROUTES.MATCH_MAKER_LISITNG,
        name: "Match Makers",
        exact: false,
        component: withTitle({ component: MatchMakers, title: PageTitles.MATCH_MAKER_LISTING })
    },
    {
        path: APP_ROUTES.CREATE_USER,
        name: "Create User",
        exact: false,
        component: withTitle({ component: CreateUser, title: PageTitles.CREATE_USER })
    },
    {
        path: APP_ROUTES.UPDATE_USER,
        name: "Update User",
        exact: false,
        component: withTitle({ component: CreateUser, title: PageTitles.UPDATE_USER })
    },
    {
        path: APP_ROUTES.REPORT_DETAILS,
        name: "Report Details",
        exact: false,
        component: withTitle({ component: ReportDetails, title: PageTitles.REPORT_DETAILS })
    },
    {
        path: APP_ROUTES.USER_DETAILS,
        name: "User Details",
        exact: true,
        component: withTitle({ component: UserDetails, title: PageTitles.USERS_DETAILS })
    },
    {
        path: APP_ROUTES.DASHBOARD_FILTERS,
        name: "Dashboard Filters",
        exact: true,
        component: withTitle({ component: DashboardFilter, title: PageTitles.DASHBOARD_FILTER })
    }
];

export default routes;
