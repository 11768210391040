import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Modal, Label } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";

import { Button } from "../Button";
import { SearchableSelect } from "../InputSelect";
import {
    assignGuardianToUser,
    getMatchMakerListing,
    getGuardianListing,
    inviteMatchMaker,
    getUserListing
} from "../../store/actions";
import { toaster } from "../../utils/toasterUtil";
import UserRoleTypes from "../../constants/UserRoleTypes";

const InvitationModal = (props) => {
    const {
        size = "md",
        isOpen = false,
        closeModal = undefined,
        userId = "",
        assignGuardianToUser,
        inviteMatchMaker,
        getUserListing,
        roleType,
        getMatchMakerListing,
        getGuardianListing
    } = props;

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [usersFetching, setUsersFetching] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");

    const userListAbortController = useRef(null);

    useEffect(() => {
        userListAbortController.current = new AbortController();
        setUsersFetching(true);

        getUserListing(
            {
                Page: page,
                ...(searchText && { Q: searchText })
            },
            userListAbortController.signal,
            false
        )
            .then((res) => {
                setTotalUsers(res.Data.Count);
                setUsers((prev) => {
                    if (!page || page === 1) {
                        return res.Data.Details;
                    }
                    return [...prev, ...res.Data.Details];
                });
            })
            .catch((err) => {})
            .finally(() => {
                setUsersFetching(false);
            });

        return () => {
            if (userListAbortController.current.signal) {
                userListAbortController.current.abort();
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchText]);

    const canSubmit = () => {
        if (roleType === UserRoleTypes.MATCH_MAKER) {
            return email?.trim().length && name?.trim().length;
        } else if (roleType === UserRoleTypes.GUARDIAN) {
            return (
                email?.trim().length && name?.trim().length && phone?.trim().length && selectedUser
            );
        } else if (roleType === UserRoleTypes.USER) {
            return email?.trim().length && name?.trim().length && phone?.trim().length;
        }
    };

    const handleSubmit = () => {
        if (!canSubmit()) {
            return;
        }

        let phoneNumber = parsePhoneNumber(phone);
        if (!(roleType === UserRoleTypes.MATCH_MAKER)) {
            let validPhone = phoneNumber ? phoneNumber.isValid() : false;
            if (!validPhone) {
                toaster({ title: "Phone number is not valid", type: "danger" });
                return;
            }
        }
        setIsLoading(true);

        let payload = {
            FullName: name,
            Email: email,
            ...(userId && { UserId: userId }),
            ...(!(roleType === UserRoleTypes.MATCH_MAKER) && {
                PhoneNumber: phoneNumber?.nationalNumber,
                Type:
                    (roleType === UserRoleTypes.GUARDIAN || roleType === UserRoleTypes.USER) &&
                    UserRoleTypes.GUARDIAN,
                PhoneExtension: "+" + phoneNumber?.countryCallingCode
            }),
            ...(selectedUser && { UserId: selectedUser?.value })
        };

        const createMember =
            roleType === UserRoleTypes.MATCH_MAKER
                ? inviteMatchMaker(payload)
                : assignGuardianToUser(payload, userId);

        createMember
            .then((res) => {
                if (roleType === UserRoleTypes.MATCH_MAKER) {
                    getMatchMakerListing({ Page: 1 });
                } else if (roleType === UserRoleTypes.GUARDIAN) {
                    getGuardianListing({ Page: 1 });
                }
                toaster({ title: res.Message, type: "success" });
                handleModalClose();
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleModalClose = () => {
        setName("");
        setPhone("");
        setEmail("");
        closeModal();
    };

    const formattedUsersList = () => {
        return users.map((item) => {
            return {
                label: item.FullName,
                value: item.UserId
            };
        });
    };

    const onUserChange = (value) => {
        setSelectedUser(value || []);
    };

    const handleUserMenuScroll = (event) => {
        const bottom =
            event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;

        const dataAvailable = users.length < totalUsers;
        if (bottom && dataAvailable && !usersFetching) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    return (
        <Modal size={size} isOpen={isOpen} className="modal-dialog-centered">
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {roleType === UserRoleTypes.MATCH_MAKER
                        ? "Add A Match Maker"
                        : "Invite A Guardian"}
                </h5>

                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Submit"
                    onClick={handleModalClose}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>
                <Row className="my-0 mx-4 mt-4">
                    <Col md="6">
                        <AvField
                            name="Name"
                            type="text"
                            label="Full Name"
                            value={name}
                            placeholder="Enter Full Name"
                            onChange={(event) => setName(event.target.value)}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: "Name is required."
                                },
                                pattern: {
                                    value: "/^[a-zA-Z ]+$/",
                                    errorMessage:
                                        "Please enter a valid name, e.g John Smith (Min 6 and Max 55 characters allowed. Special characters are not allowed"
                                },
                                maxLength: {
                                    value: 55,
                                    errorMessage:
                                        "Please enter a valid name, e.g John Smith (Min 6 and Max 55 characters allowed. Special characters are not allowed"
                                },
                                minLength: {
                                    value: 6,
                                    errorMessage:
                                        "Please enter a valid name, e.g John Smith (Min 6 and Max 55 characters allowed. Special characters are not allowed"
                                }
                            }}
                            required
                        />
                    </Col>
                    <Col md="6">
                        <AvField
                            name="email"
                            label="Email"
                            type="email"
                            value={email}
                            placeholder="Enter Email"
                            errorMessage="Please enter a valid email ID"
                            onChange={(event) => setEmail(event.target.value)}
                            validate={{
                                required: {
                                    value: true,
                                    errorMessage: "Email is required."
                                },
                                maxLength: {
                                    value: 128,
                                    errorMessage: "Max 128 characters are allowed"
                                }
                            }}
                            required
                        />
                    </Col>
                </Row>

                {roleType !== UserRoleTypes.MATCH_MAKER && (
                    <Row className="my-0 mx-4">
                        <Col md="6">
                            <AvField
                                type="text"
                                name="phoneNumber"
                                label="Phone Number"
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                                placeholder="Enter Phone Number"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "Phone Number is required."
                                    },
                                    minLength: {
                                        value: 10,
                                        errorMessage: "Please enter a valid phone number"
                                    },
                                    pattern: {
                                        value: "/^[+][0-9]+$/",
                                        errorMessage: "Please enter a valid number "
                                    }
                                }}
                                required
                            />
                        </Col>
                        {roleType === UserRoleTypes.GUARDIAN && (
                            <Col md="6">
                                <Label>Select User's Guardian</Label>
                                <div onScroll={handleUserMenuScroll}>
                                    <SearchableSelect
                                        options={formattedUsersList()}
                                        isLoading={usersFetching}
                                        value={selectedUser}
                                        onChange={onUserChange}
                                        placeholder={"Select User"}
                                        closeMenuOnSelect={true}
                                        isClearable
                                        onMenuScrollToBottom={handleUserMenuScroll}
                                        onInputChange={(value) => setSearchText(value)}
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>
                )}

                <div className="modal-footer">
                    <Button
                        btnText={"Submit"}
                        type="submit"
                        isLoading={isLoading}
                        disabled={isLoading || !canSubmit()}
                    />
                </div>
            </AvForm>
        </Modal>
    );
};

const mapDispatchToProps = {
    assignGuardianToUser,
    inviteMatchMaker,
    getUserListing,
    getMatchMakerListing,
    getGuardianListing
};

export default connect(null, mapDispatchToProps)(InvitationModal);
