import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

class Breadcrumbs extends Component {
    getTitleLink = () => {
        const { title, titleLink = null } = this.props;
        if (title === "Dashboard") {
            return getRoute(APP_ROUTES.DASHBOARD);
        } else if (titleLink) {
            return titleLink;
        }
        return "#";
    };

    render() {
        const { withBackButton = false, history } = this.props;
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                                {withBackButton && (
                                    <i
                                        className="mdi mdi-arrow-left mr-2 breadcrumb-back"
                                        onClick={history.goBack}
                                    ></i>
                                )}
                                <h4 className="mb-0 font-size-18">{this.props.breadcrumbItem}</h4>
                            </div>
                            <div className="page-title-right">
                                <Breadcrumb listClassName="m-0">
                                    <BreadcrumbItem>
                                        <Link to={this.getTitleLink()}>{this.props.title}</Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <Link to="#">{this.props.breadcrumbItem}</Link>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(Breadcrumbs);
