import React, { Component } from "react";

import { connect } from "react-redux";

// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import "./Header.scss";

import logo from "../../assets/images/hc-lg.png";
import logoLightPng from "../../assets/images/hc-lg.png";
import logoLightSvg from "../../assets/images/hc-sm.png";
import logoDark from "../../assets/images/logo-dark.png";

//i18n
import { withNamespaces } from "react-i18next";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            isMobile: false
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleFullscreen = this.toggleFullscreen.bind(this);
        this.mediaListner = null;
    }

    componentDidMount() {
        this.mediaListner = window.matchMedia("(max-width: 992px)");
        this.mediaListner.addEventListener("change", this.handleScreenSizeChange);
    }

    componentWillUnmount() {
        this.mediaListner.removeEventListener("change", this.handleScreenSizeChange);
    }

    handleScreenSizeChange = (data) => {
        if (data.matches) {
            this.setState({ isMobile: true });
        } else {
            this.setState({ isMobile: false });
        }
    };

    /**
     * Toggle sidebar
     */
    toggleMenu() {
        this.props.toggleMenuCallback();
    }

    toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    render() {
        const { leftSideBarType } = this.props;
        const { isMobile } = this.state;
        return (
            <React.Fragment>
                <header className="custom-header" id="page-topbar">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box">
                                <div className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logo} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoDark} alt="" height="17" />
                                    </span>
                                </div>

                                <div className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoLightSvg} alt="" />
                                    </span>
                                    <span className="logo-lg">
                                        <img src={logoLightPng} alt="" />
                                    </span>
                                </div>
                            </div>

                            <button
                                type="button"
                                onClick={this.toggleMenu}
                                className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                                id="vertical-menu-btn"
                            >
                                {((leftSideBarType === "default" && !isMobile) ||
                                    (leftSideBarType === "condensed" && isMobile)) && (
                                    <i className="mdi mdi-chevron-left"></i>
                                )}
                                {((leftSideBarType === "condensed" && !isMobile) ||
                                    (leftSideBarType === "default" && isMobile)) && (
                                    <i className="mdi mdi-chevron-right"></i>
                                )}
                            </button>
                        </div>
                        <div className="d-flex">
                            <div className="dropdown d-inline-block d-lg-none ml-2">
                                <button
                                    onClick={() => {
                                        this.setState({ isSearch: !this.state.isSearch });
                                    }}
                                    type="button"
                                    className="btn header-item noti-icon waves-effect"
                                    id="page-header-search-dropdown"
                                >
                                    <i className="mdi mdi-magnify"></i>
                                </button>
                                <div
                                    className={
                                        this.state.isSearch
                                            ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                                            : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                                    }
                                    aria-labelledby="page-header-search-dropdown"
                                >
                                    <form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search ..."
                                                    aria-label="Recipient's username"
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        className="btn btn-primary"
                                                        type="submit"
                                                    >
                                                        <i className="mdi mdi-magnify"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            <ProfileMenu />
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { layoutType, leftSideBarType } = state.Layout;
    return { layoutType, leftSideBarType };
};

export default connect(mapStatetoProps, null)(withNamespaces()(Header));
