import { injectBaseConstantMethods } from "./BaseConstants";

const TRANSACTIONS_TYPES = {
    CHARGE: 1,
    REFUND: 2
};

const displayTextKeys = {
    [TRANSACTIONS_TYPES.CHARGE]: "Charge",
    [TRANSACTIONS_TYPES.REFUND]: "Refund"
};

const labelClass = {
    [TRANSACTIONS_TYPES.CHARGE]: "success",
    [TRANSACTIONS_TYPES.REFUND]: "danger"
};

export default injectBaseConstantMethods(TRANSACTIONS_TYPES, displayTextKeys, labelClass);
