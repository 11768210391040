import { fetchUtil } from "../../utils/fetchUtil";
import { appendQueryParams } from "../../utils/urlUtils";

export const COUNTRY_PARAMS = {
    USER_EXISTANCE: 1,
    TRANSACTION_EXISTANCE: 1
};

export const getCountryListing = (params = {}) => (dispatch, getState) => {
    const token = getState().userAuth.user.Token;

    return fetchUtil({
        url: appendQueryParams("/common/countries", {
            ...params
        }),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
