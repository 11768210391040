export default {
    LOGIN: "Login",
    FORGOT_PASSWORD: "Forgot Password",
    RESET_PASSWORD: "Reset Password",
    SIGN_UP: "Signup",
    DASHBOARD: "Dashboard",
    PAYMENT_PLANS: "Payment Plans",
    CREATE_PACKAGE: "Create Package",
    CUSTOMER_QUERY: "Customer Query",
    CUSTOMER_QUERY_FEEDBACK: "Customer Query Feedback",
    USER_REPORTING: "User Reporting",
    USER_LISTING: "Users",
    UPDATE_PACKAGE: "Update Package",
    GUARDIAN_LISTING: "Guardians",
    MATCH_MAKER_LISTING: "Match Makers",
    CREATE_USER: "Create User",
    UPDATE_USER: "Update User",
    REPORT_DETAILS: "Report Details",
    USER_DETAILS: "User Details",
    DASHBOARD_FILTER: "Dashboard Filter",
    TRANSACTIONS: "Transactions"
};
