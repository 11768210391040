import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

import { Button } from "../Button";
import { Checkbox } from "../Checkbox";

import "./DataTable.scss";

const MultiFilterBox = (props) => {
    const { options } = props;
    const { title, filterConstants = {}, onFilterSelect = () => {}, currentFilters = [] } = options;

    const [open, setOpen] = useState(false);
    const [filters, setFilters] = useState(renderFormattedFilters());

    useEffect(() => {
        if (Boolean(filters.length)) {
            let updated = filters.map((item) => {
                return {
                    ...item,
                    isChecked: currentFilters.includes(item.value) ? true : false
                };
            });

            setFilters(updated);
        }
    }, [open]);

    const handleMenuToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    function renderFormattedFilters() {
        return Object.values(filterConstants).map((item) => {
            return {
                isChecked: false,
                value: item,
                title: filterConstants.getDisplayTextKey(item)
            };
        });
    }

    const handleFilterChange = (key, value) => {
        let allFilters = [...filters];

        let updatedFilters = allFilters.map((item) => {
            if (item.value === key) {
                return {
                    ...item,
                    isChecked: !value
                };
            }
            return item;
        });

        setFilters(updatedFilters);
    };

    const handleFilterSubmit = () => {
        let valueExists = filters.some((item) => item.isChecked === true);

        let selected = [];

        if (valueExists) {
            selected = filters.filter((item) => item.isChecked === true).map((item) => item.value);
        }

        onFilterSelect(selected);
        handleMenuToggle();
    };

    return (
        <Dropdown isOpen={open} toggle={() => {}}>
            <DropdownToggle
                onClick={handleMenuToggle}
                tag="div"
                className="text-muted data-table-head sortable"
            >
                {title}
                {open ? (
                    <i className="mdi mdi-chevron-up"></i>
                ) : (
                    <i className="mdi mdi-chevron-down"></i>
                )}
            </DropdownToggle>
            <DropdownMenu>
                {Boolean(filters.length) &&
                    filters.map((item, index) => {
                        const { isChecked, value, title } = item;

                        return (
                            <DropdownItem key={index}>
                                <Checkbox
                                    title={title}
                                    checked={isChecked}
                                    id={index}
                                    onChange={() => handleFilterChange(value, isChecked)}
                                />
                            </DropdownItem>
                        );
                    })}
                <div className="d-flex flex-row justify-content-end mr-4">
                    <Button btnText="Apply" onClick={handleFilterSubmit} />
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default MultiFilterBox;
