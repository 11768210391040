import React from "react";
import { Spinner } from "reactstrap";

import "./FilterButton.scss";

const FilterButton = (props) => {
    const { text, classes, onClick, isLoading = false } = props;
    return (
        <div className={`mb-0 filter-btn ${classes}`} onClick={onClick}>
            {isLoading ? <Spinner size="sm" /> : <span>{text}</span>}
        </div>
    );
};

export default FilterButton;
