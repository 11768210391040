import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, CardBody, Row, Col, CardTitle, FormGroup, Spinner } from "reactstrap";

import "../DashboardFilter.scss";
import OptionFilter from "../OptionFilter";
import SelectFilter from "../SelectFilter";
import { PlatformConstants, PlanCategory } from "../../../constants";
import { SplineChart } from "../../../components/Chart";
import { DropdownButton } from "../../../components/DropdownButton";
import { FilterButton } from "../../../components/FilterButton";
import { RangePicker } from "../../../components/RangePicker";
import {
    getCountryListing,
    getRevenueAnalytics,
    downloadCSV,
    downloadPDF,
    COUNTRY_PARAMS
} from "../../../store/actions";
import { Checkbox } from "../../../components/Checkbox";
import { SearchableSelect } from "../../../components/InputSelect";
import { useDispatch } from "react-redux";
import { formatGraphLabels } from "../../../utils/commonUtils";
import { downloadCSVData, downloadPDFData } from "../../../utils/downloadUtil";

const USER_FILTERS = {
    LOCATION: { label: "Location", apiKey: "Location" },
    PLATFORM: { label: "Platform", apiKey: "Platform" },
    PAYMENT_PLAN: { label: "Payment Plan", apiKey: "Package" }
};

const DashboardRevenueFilter = (props) => {
    const { filters, commonFilters, isFilterOpen, setIsFilterOpen } = props;
    const [graphData, setGraphData] = useState(null);
    const [comparedGraphData, setComparedGraphData] = useState(null);
    const [enableCompare, setEnableCompare] = useState(false);
    const [selectedFilterToCompare, setSelectedFilterToCompare] = useState(null);
    const [comparedFilterValue, setComparedFilterValue] = useState(null);
    const [platform, setPlatform] = useState(null);
    const [transactionPlan, setTransactionPlan] = useState(null);
    const [toggleOpen, setToggleOpen] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        comparedStartDate: null,
        comparedEndDate: null
    });
    const dispatch = useDispatch();
    const revenueAnalyticsAbortController = useRef();

    let filterOne = useMemo(() => {
        return {
            ...(Object.keys(filters?.location).length && {
                Location: filters?.location?.value?.Id
            }),
            ...(platform && { Platform: platform }),
            ...(transactionPlan && { Package: transactionPlan }),

            ...(dateRange.startDate &&
                dateRange.endDate && {
                    DateRange: {
                        StartDate: dateRange.startDate.format("YYYY-MM-DD"),
                        EndDate: dateRange.endDate.format("YYYY-MM-DD")
                    }
                })
        };
    }, [filters, dateRange, platform, transactionPlan]);

    let filterTwo = useMemo(() => {
        let comparedValue =
            typeof comparedFilterValue === "object"
                ? comparedFilterValue?.value?.Id
                : comparedFilterValue;

        return {
            ...filterOne,
            ...(comparedFilterValue && {
                [selectedFilterToCompare?.value?.apiKey]: comparedValue
            }),

            ...(dateRange.comparedStartDate &&
                dateRange.comparedEndDate && {
                    DateRange: {
                        StartDate: dateRange.comparedStartDate.format("YYYY-MM-DD"),
                        EndDate: dateRange.comparedEndDate.format("YYYY-MM-DD")
                    }
                })
        };
    }, [filterOne, comparedFilterValue, dateRange]);

    useEffect(() => {
        revenueAnalyticsAbortController.current = new AbortController();

        dispatch(
            getRevenueAnalytics(
                {
                    Filter1: filterOne,
                    ...(((enableCompare && comparedFilterValue) ||
                        (dateRange.comparedStartDate && dateRange.comparedEndDate)) && {
                        Filter2: filterTwo
                    })
                },
                revenueAnalyticsAbortController.current.signal
            )
        )
            .then((res) => {
                if (res && res.RevenueAnalytics1) {
                    setGraphData({
                        data: Object.values(res.RevenueAnalytics1).map((data) => data.TotalRevenue),
                        categories: formatGraphLabels(
                            Object.keys(res.RevenueAnalytics1),
                            res.Format
                        )
                    });
                }

                if (res && res.RevenueAnalytics2) {
                    setComparedGraphData({
                        data: Object.values(res.RevenueAnalytics2).map((data) => data.TotalRevenue),
                        categories: formatGraphLabels(
                            Object.keys(res.RevenueAnalytics2),
                            res.Format
                        )
                    });
                } else {
                    setComparedGraphData(null);
                }
            })
            .catch((err) => {});

        return () => {
            if (revenueAnalyticsAbortController.current) {
                revenueAnalyticsAbortController.current.abort();
            }
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dateRange, comparedFilterValue, filterOne, filterTwo]);

    const handleFilterOpenClose = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const handleMenuToggle = (e) => {
        setToggleOpen((prevOpen) => !prevOpen);
    };

    const getFilterOptions = () => {
        return Object.values(USER_FILTERS).map((item) => ({
            label: item.label,
            value: item
        }));
    };

    const handlePlatformFilterChange = (isComparing = false, value) => {
        if (isComparing) {
            setComparedFilterValue(value);
            return;
        }

        setPlatform(value);
    };

    const handlePlanFilterChange = (isComparing = false, value) => {
        if (isComparing) {
            setComparedFilterValue(value);
            return;
        }

        setTransactionPlan(value);
    };

    const handleCompareToggle = () => {
        if (enableCompare) {
            setSelectedFilterToCompare(null);
            setComparedFilterValue(null);
        } else {
            if (
                dateRange.startDate ||
                dateRange.endDate ||
                dateRange.comparedStartDate ||
                dateRange.comparedEndDate
            ) {
                setDateRange({
                    startDate: null,
                    endDate: null,
                    comparedStartDate: null,
                    comparedEndDate: null
                });
            }
        }
        setEnableCompare((prev) => !prev);
    };

    const handleTimeRangeApplied = (val) => {
        if (enableCompare) {
            setSelectedFilterToCompare(null);
            setComparedFilterValue(null);
            setEnableCompare(false);
        }
        setDateRange(val);
    };

    const renderDate = () => {
        if (dateRange.startDate && dateRange.endDate) {
            return (
                "From " +
                dateRange.startDate.format("YYYY") +
                " Till " +
                dateRange.endDate.format("YYYY")
            );
        } else {
            return "From " + new Date().getFullYear() + " Till " + new Date().getFullYear();
        }
    };

    const getCategories = () => {
        if (graphData?.categories && comparedGraphData?.categories) {
            return [...graphData?.categories, ...comparedGraphData?.categories];
        }
        return graphData?.categories;
    };

    const handleCSVExport = () => {
        setToggleOpen(false);

        dispatch(
            downloadCSV("/analytics/revenues/csv", {
                Filter1: filterOne,
                ...(((enableCompare && comparedFilterValue) ||
                    (dateRange.comparedStartDate && dateRange.comparedEndDate)) && {
                    Filter2: filterTwo
                })
            })
        )
            .then((res) => {
                downloadCSVData(res);
            })
            .catch((err) => {});
    };

    const handlePDFExport = () => {
        setToggleOpen(false);

        dispatch(
            downloadPDF("/analytics/revenues/pdf", {
                Filter1: filterOne,
                ...(((enableCompare && comparedFilterValue) ||
                    (dateRange.comparedStartDate && dateRange.comparedEndDate)) && {
                    Filter2: filterTwo
                })
            })
        )
            .then((res) => {
                downloadPDFData(res);
            })
            .catch((err) => {});
    };

    const renderLocationFilter = () => {
        return (
            <Col md="2">
                <SelectFilter
                    title="Select Location"
                    requestAction={() =>
                        getCountryListing({
                            TransactionExistence: COUNTRY_PARAMS.TRANSACTION_EXISTANCE
                        })
                    }
                    isOpen={true}
                    placeholder="Select Location"
                    filterValue={comparedFilterValue}
                    onChange={(val) => setComparedFilterValue(val || [])}
                    getOptions={(data) =>
                        data.map((item) => ({
                            label: item.Name,
                            value: item
                        }))
                    }
                />
            </Col>
        );
    };

    const renderPaymentPlanFilter = (isComparing = false) => {
        return (
            <Col md="3">
                <OptionFilter
                    title="Select Payment Plan"
                    isOpen={isComparing ? true : isFilterOpen}
                    filterConstants={PlanCategory}
                    excludeFilters={[PlanCategory.ALL]}
                    selectedFilterValue={isComparing ? comparedFilterValue : transactionPlan}
                    onChange={(val) => handlePlanFilterChange(isComparing, val)}
                />
            </Col>
        );
    };

    const renderPlatformFilter = (isComparing = false) => {
        return (
            <Col md="2">
                <OptionFilter
                    title={"Platform"}
                    isOpen={isComparing ? true : isFilterOpen}
                    filterConstants={PlatformConstants}
                    selectedFilterValue={isComparing ? comparedFilterValue : platform}
                    onChange={(val) => handlePlatformFilterChange(isComparing, val)}
                />
            </Col>
        );
    };

    const renderComparedFilters = () => {
        let comparedFilter = null;

        switch (selectedFilterToCompare?.label) {
            case USER_FILTERS.LOCATION.label:
                comparedFilter = renderLocationFilter();
                break;

            case USER_FILTERS.PLATFORM.label:
                comparedFilter = renderPlatformFilter(true);
                break;

            case USER_FILTERS.PAYMENT_PLAN.label:
                comparedFilter = renderPaymentPlanFilter(true);
                break;
            default:
                comparedFilter = null;
                break;
        }
        return comparedFilter;
    };

    return (
        <div>
            <Row className="pb-2">
                <Col md="2">
                    <div className="d-flex align-items-center">
                        <FilterButton text="Filter" onClick={handleFilterOpenClose} />
                        <i
                            onClick={handleFilterOpenClose}
                            className={`cursor-pointer bx bx-chevron-${
                                isFilterOpen ? "down" : "right"
                            } chevron-icon`}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="pb-4">
                {isFilterOpen && renderPlatformFilter()} {isFilterOpen && renderPaymentPlanFilter()}
                {commonFilters}
            </Row>

            <Row className="px-3">
                <FormGroup className="my-2">
                    <Checkbox
                        title="Compare with:"
                        checked={enableCompare}
                        onChange={handleCompareToggle}
                        id="enable-copare"
                    />
                </FormGroup>
                {enableCompare && (
                    <Col md="2">
                        <SearchableSelect
                            options={getFilterOptions()}
                            placeholder="Select Filter"
                            isClearable
                            value={selectedFilterToCompare}
                            onChange={(val) => {
                                setComparedFilterValue(null);
                                setSelectedFilterToCompare(val);
                            }}
                        />
                    </Col>
                )}
                {renderComparedFilters()}
            </Row>
            <div className="filter-main-container" />
            <Row>
                <Col md="12">
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <p>{renderDate()}</p>
                        <div className="d-flex">
                            <RangePicker value={dateRange} onChange={handleTimeRangeApplied} />
                            <DropdownButton
                                handlePDF={handlePDFExport}
                                handleCSV={handleCSVExport}
                                handleToggle={handleMenuToggle}
                                toggleOpen={toggleOpen}
                            />
                        </div>
                    </div>
                </Col>
                <Col md="12">
                    <Card className="mt-3">
                        <CardBody>
                            <CardTitle className="mb-4"> Total Revenue </CardTitle>
                            {graphData ? (
                                <SplineChart
                                    graph1Title="Revenue"
                                    data={graphData?.data}
                                    graph2Title="Compared Revenue"
                                    data2={comparedGraphData?.data}
                                    categories={getCategories()}
                                />
                            ) : (
                                <div className="d-flex align-items-center justify-content-center dashboard-graph-height">
                                    <Spinner size="md" color="primary" />
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DashboardRevenueFilter;
