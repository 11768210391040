import React, { useState, useEffect, useRef, useMemo } from "react";
import { Card, CardBody, Row, Col, CardTitle, Spinner } from "reactstrap";

import "../DashboardFilter.scss";
import { DashboardUserFilterTypes } from "../../../constants";
import { SplineChart } from "../../../components/Chart";
import { FilterButton } from "../../../components/FilterButton";
import { RangePicker } from "../../../components/RangePicker";
import { DropdownButton } from "../../../components/DropdownButton";
import { getUsersAnalytics, downloadCSV, downloadPDF } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { formatGraphLabels } from "../../../utils/commonUtils";
import { downloadCSVData, downloadPDFData } from "../../../utils/downloadUtil";

const DashboardCustomerFilter = (props) => {
    const {
        filters,
        commonFilters,
        isFilterOpen,
        setIsFilterOpen,
        setConstantFilters = () => {}
    } = props;
    const [graphData, setGraphData] = useState(null);
    const [comparedGraphData, setComparedGraphData] = useState(null);
    const [enableCompare, setEnableCompare] = useState(false);
    const [selectedFilterToCompare, setSelectedFilterToCompare] = useState(null);
    const [comparedFilterValue, setComparedFilterValue] = useState(
        DashboardUserFilterTypes.PAID_USERS
    );
    const [toggleOpen, setToggleOpen] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null,
        comparedStartDate: null,
        comparedEndDate: null
    });
    let filterOne = useMemo(() => {
        return {
            UserType: filters.user,
            ...(filters.gender && { Gender: filters.gender }),
            ...(filters.maxAge &&
                filters.minAge && { StartAge: filters.minAge, EndAge: filters.maxAge }),
            ...(Object.keys(filters?.location).length && {
                Location: filters?.location?.value?.Id
            }),
            ...(filters.plan && { PaymentPlan: filters.plan }),
            ...(dateRange.startDate &&
                dateRange.endDate && {
                    DateRange: {
                        StartDate: dateRange.startDate.format("YYYY-MM-DD"),
                        EndDate: dateRange.endDate.format("YYYY-MM-DD")
                    }
                })
        };
    }, [filters, dateRange]);
    let filterTwo = useMemo(() => {
        let comparedValue =
            typeof comparedFilterValue === "object"
                ? comparedFilterValue?.value?.Id
                : comparedFilterValue;
        return {
            ...filterOne,
            UserType: comparedValue,

            ...(dateRange.comparedStartDate &&
                dateRange.comparedEndDate && {
                    DateRange: {
                        StartDate: dateRange.comparedStartDate.format("YYYY-MM-DD"),
                        EndDate: dateRange.comparedEndDate.format("YYYY-MM-DD")
                    }
                })
        };
    }, [filterOne, comparedFilterValue, dateRange]);

    const dispatch = useDispatch();
    const userAnalyticsAbortController = useRef();

    useEffect(() => {
        setConstantFilters([
            {
                key: "Free Customers",
                value: Boolean(graphData) && graphData?.data ? Math.max(...graphData?.data) : 0,
                class: "selected-checkbox-compared"
            },
            {
                key: "Paid Customers",
                value:
                    Boolean(comparedFilterValue) && comparedGraphData?.data
                        ? Math.max(...comparedGraphData?.data)
                        : 0,
                class: "selected-checkbox"
            }
        ]);
    }, [comparedGraphData, graphData]);

    useEffect(() => {
        userAnalyticsAbortController.current = new AbortController();

        dispatch(
            getUsersAnalytics(
                {
                    Filter1: filterOne,
                    Filter2: filterTwo
                },
                userAnalyticsAbortController.current.signal
            )
        )
            .then((res) => {
                if (res && res.UserAnalytics1) {
                    setGraphData({
                        data: Object.values(res.UserAnalytics1).map((data) => data.TotalUsers),
                        categories: formatGraphLabels(Object.keys(res.UserAnalytics1), res.Format)
                    });
                }

                if (res && res.UserAnalytics2) {
                    setComparedGraphData({
                        data: Object.values(res.UserAnalytics2).map((data) => data.TotalUsers),
                        categories: formatGraphLabels(Object.keys(res.UserAnalytics2), res.Format)
                    });
                } else {
                    setComparedGraphData(null);
                }
            })
            .catch((err) => {});

        return () => {
            if (userAnalyticsAbortController.current) {
                userAnalyticsAbortController.current.abort();
            }
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, dateRange, comparedFilterValue, filterOne, filterTwo]);

    const handleMenuToggle = (e) => {
        setToggleOpen((prevOpen) => !prevOpen);
    };

    const handleFilterOpenClose = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const handleTimeRangeApplied = (val) => {
        if (enableCompare) {
            setSelectedFilterToCompare(null);
            setComparedFilterValue(null);
            setEnableCompare(false);
        }
        setDateRange(val);
    };

    const renderDate = () => {
        if (dateRange.startDate && dateRange.endDate) {
            return (
                "From " +
                dateRange.startDate.format("YYYY") +
                " Till " +
                dateRange.endDate.format("YYYY")
            );
        } else {
            return "From " + new Date().getFullYear() + " Till " + new Date().getFullYear();
        }
    };

    const getCategories = () => {
        if (graphData?.categories && comparedGraphData?.categories) {
            return [...graphData?.categories, ...comparedGraphData?.categories];
        }
        return graphData?.categories;
    };

    const handleCSVExport = () => {
        setToggleOpen(false);

        dispatch(
            downloadCSV("/analytics/users/csv", {
                Filter1: filterOne,
                ...(((enableCompare && comparedFilterValue) ||
                    (dateRange.comparedStartDate && dateRange.comparedEndDate)) && {
                    Filter2: filterTwo
                })
            })
        )
            .then((res) => {
                downloadCSVData(res);
            })
            .catch((err) => {});
    };

    const handlePDFExport = () => {
        setToggleOpen(false);

        dispatch(
            downloadPDF("/analytics/users/pdf", {
                Filter1: filterOne,
                ...(((enableCompare && comparedFilterValue) ||
                    (dateRange.comparedStartDate && dateRange.comparedEndDate)) && {
                    Filter2: filterTwo
                })
            })
        )
            .then((res) => {
                downloadPDFData(res);
            })
            .catch((err) => {});
    };

    return (
        <div>
            <Row className="pb-2">
                <Col md="2">
                    <div className="d-flex align-items-center">
                        <FilterButton text="Filter" onClick={handleFilterOpenClose} />
                        <i
                            onClick={handleFilterOpenClose}
                            className={`cursor-pointer bx bx-chevron-${
                                isFilterOpen ? "down" : "right"
                            } chevron-icon`}
                        />
                    </div>
                </Col>
            </Row>
            <Row className="pb-4">{commonFilters}</Row>

            <div className="filter-main-container" />
            <Row>
                <Col md="12">
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <p>{renderDate()}</p>
                        <div className="d-flex">
                            <RangePicker value={dateRange} onChange={handleTimeRangeApplied} />
                            {/* <FilterButton
                                onClick={handleExportClick}
                                text="Export"
                                classes="font-weight-bold"
                            /> */}
                            <DropdownButton
                                handlePDF={handlePDFExport}
                                handleCSV={handleCSVExport}
                                handleToggle={handleMenuToggle}
                                toggleOpen={toggleOpen}
                            />
                        </div>
                    </div>
                </Col>
                <Col md="12">
                    <Card className="mt-3">
                        <CardBody>
                            <CardTitle className="mb-4"> Total Customers </CardTitle>
                            {graphData ? (
                                <SplineChart
                                    graph1Title="Free Customers"
                                    graph2Title="Paid Customers"
                                    data={graphData?.data}
                                    data2={comparedGraphData?.data}
                                    categories={getCategories()}
                                />
                            ) : (
                                <div className="d-flex align-items-center justify-content-center dashboard-graph-height">
                                    <Spinner size="md" color="primary" />
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default DashboardCustomerFilter;
