import { injectBaseConstantMethods } from "./BaseConstants";

const PLATFORM_CONSTANTS = {
    ANDROID: 1,
    IOS: 2
};

const displayTextKeys = {
    [PLATFORM_CONSTANTS.ANDROID]: "Android",
    [PLATFORM_CONSTANTS.IOS]: "IOS"
};

const labelClass = {
    [PLATFORM_CONSTANTS.ANDROID]: "success",
    [PLATFORM_CONSTANTS.IOS]: "info"
};

export default injectBaseConstantMethods(PLATFORM_CONSTANTS, displayTextKeys, labelClass);
