import {
    TRANSACTION_LISTING_REQUEST,
    TRANSACTION_LISTING_FAILURE,
    TRANSACTION_LISTING_SUCCESS
} from "../actions";

const initialState = {
    fetching: false,
    list: [],
    totalTransactions: 0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case TRANSACTION_LISTING_REQUEST:
            return {
                ...state,
                fetching: true,
                list: [],
                totalTransactions: 0
            };

        case TRANSACTION_LISTING_SUCCESS:
            return {
                ...state,
                fetching: false,
                list: action.payload,
                totalTransactions: action.totalTransactions
            };

        case TRANSACTION_LISTING_FAILURE:
            return {
                ...state,
                fetching: false,
                list: [],
                totalTransactions: 0
            };
        default:
            return state;
    }
}
