import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardBody, UncontrolledTooltip, Button } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import { DataTable } from "../../components/DataTable";
import { Pagination } from "../../components/Pagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { SweetAlert } from "../../components/Alert";
import { CustomBadge } from "../../components/Badge";
import { SearchHeader } from "../../components/SearchHeader";
import { getRoute, APP_ROUTES } from "../../helpers/routeHelpers";
import { getUserListing, removeUser, userRestore } from "../../store/actions";
import { UserStatus, UserSubscriptionType, UserRoles, SortOrder } from "../../constants";
import { dateTimeFormat, convertDateTime } from "../../utils/dateFormatUtils";
import { toaster } from "../../utils/toasterUtil";
import { InvitationModal } from "../../components/InvitationModal";
import FiltersModal from "./FiltersModal";
import { getHourFormatFromMilliSeconds } from "../../utils/commonUtils";

const USER_COLUMNS = {
    FULL_NAME: "Full Name",
    EMAIL: "Email",
    TYPE: "Type",
    USER_TYPE: "User Type",
    STATUS: "Status",
    TIME_SPENT: "Last Used At",
    TOTAL_USAGE_TIME: "Total Usage Time",
    POPULAR_USAGE_TIME: "Popular Usage Time",
    JOINING_DATE: "Joining Date",
    ACTIONS: "Actions"
};

const SORTABLE_COLUMNS = {
    [USER_COLUMNS.FULL_NAME]: "FullName",
    [USER_COLUMNS.EMAIL]: "Email",
    [USER_COLUMNS.USER_TYPE]: "SubscriptionType",
    [USER_COLUMNS.JOINING_DATE]: "CreatedAt",
    [USER_COLUMNS.STATUS]: "Status",
    [USER_COLUMNS.TIME_SPENT]: "LastUsedAt"
};

const ALERT_MODES = {
    DELETE: "DELETE",
    RESTORE: "RESTORE"
};

const USER_ROUTE = {
    [UserRoles.USER]: "user"
};

const Users = (props) => {
    const { data, fetching, getUserListing, totalUsers, removeUser, userRestore } = props;

    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [guardianModal, setGuardianModal] = useState(false);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);
    const [statusFilters, setStatusFilters] = useState([]);
    const [filtersModal, setFiltersModal] = useState(false);

    const userListingAbortController = useRef(null);
    const history = useHistory();

    useEffect(() => {
        userListingAbortController.current = new AbortController();

        getUserListing(
            {
                Page: page,
                ...(currentSortColumn &&
                    currentSortOrder && {
                        Column: currentSortColumn,
                        Direction: currentSortOrder
                    }),
                ...(searchText && { Q: searchText }),
                ...(Boolean(statusFilters.length) && { MultipleStatus: statusFilters })
            },
            userListingAbortController.current.signal
        );

        return () => {
            if (userListingAbortController.current.signal) {
                userListingAbortController.current.abort();
            }
        };

        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchText, currentSortColumn, currentSortOrder, statusFilters, getUserListing]);

    const handleSortClick = (data) => {
        let sortOrder;
        if (!currentSortOrder || currentSortOrder === SortOrder.DESC) {
            sortOrder = SortOrder.ASC;
        } else {
            sortOrder = SortOrder.DESC;
        }
        setCurrentSortColumn(SORTABLE_COLUMNS[data.title]);
        setCurrentSortOrder(sortOrder);
    };

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };

    const handleSearchChange = (e) => {
        setPage(1);
        setSearchText(e.target.value);
    };

    const toggleUserDeleteAlert = (id) => {
        setSelectedUserId(id);
        setShowAlert(ALERT_MODES.DELETE);
    };

    const toggleUserRestoreAlert = (id) => {
        setSelectedUserId(id);
        setShowAlert(ALERT_MODES.RESTORE);
    };

    const handleUserDelete = () => {
        setIsLoading(true);

        let payload = {
            UserId: selectedUserId
        };

        removeUser(payload)
            .then((res) => {
                toaster({ title: props.t(res.Message), type: "success" });
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
                setShowAlert(false);
                setSelectedUserId("");
            });
    };

    const handleUserRestore = () => {
        let payload = {
            UserId: selectedUserId
        };

        setIsLoading(true);

        userRestore(payload)
            .then((res) => {
                toaster({ title: props.t(res.Message), type: "success" });
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
                setShowAlert(false);
            });
    };

    const toggleGuardianModal = (id) => {
        setSelectedUserId(id);
        setGuardianModal(true);
    };

    const renderPopularUsageTime = (userData) => {
        if (
            userData &&
            userData?.MostPopularUsageTime?.Start &&
            userData?.MostPopularUsageTime?.End
        ) {
            return (
                moment(userData?.MostPopularUsageTime?.Start, "HH:mm").format("hh:mm a") +
                " - " +
                moment(userData?.MostPopularUsageTime?.End, "HH:mm").format("hh:mm a")
            );
        }

        return "N/A";
    };

    const handleFilterChange = (filters) => {
        setPage(1);
        setStatusFilters(filters);
    };

    const handleFilterClick = () => {
        setFiltersModal(!filtersModal);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t("Dashboard")} breadcrumbItem={props.t("Users")} />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <SearchHeader
                                        buttonText="Add New User"
                                        value={searchText}
                                        onChange={handleSearchChange}
                                        onButtonClick={() =>
                                            history.push(
                                                getRoute(APP_ROUTES.CREATE_USER, {
                                                    type: USER_ROUTE[UserRoles.USER]
                                                })
                                            )
                                        }
                                    />

                                    <DataTable
                                        data={data}
                                        loading={fetching}
                                        currentSortOrder={currentSortOrder}
                                        currentSortColumn={currentSortColumn}
                                        onSortClick={handleSortClick}
                                        config={[
                                            {
                                                title: USER_COLUMNS.FULL_NAME,
                                                render: (data) => data?.FullName || "-",
                                                sortColumn:
                                                    SORTABLE_COLUMNS[USER_COLUMNS.FULL_NAME],
                                                isSortable: true,
                                                showAlphabetIcon: true
                                            },
                                            {
                                                title: USER_COLUMNS.EMAIL,
                                                render: (data) => data?.Email || "-",
                                                sortColumn: SORTABLE_COLUMNS[USER_COLUMNS.EMAIL],
                                                isSortable: true,
                                                showAlphabetIcon: true
                                            },
                                            {
                                                title: USER_COLUMNS.STATUS,
                                                onFilterSelect: (key, value) =>
                                                    handleFilterChange(key, value),
                                                onFilterClick: handleFilterClick,
                                                render: (data) => (
                                                    <CustomBadge
                                                        color={UserStatus.getLabelClass(
                                                            data.Status
                                                        )}
                                                        classes={UserStatus.getLabelClass(
                                                            data.Status
                                                        )}
                                                    >
                                                        {props.t(
                                                            UserStatus.getDisplayTextKey(
                                                                data.Status
                                                            )
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: USER_COLUMNS.JOINING_DATE,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[USER_COLUMNS.JOINING_DATE],
                                                isSortable: true,
                                                render: (data) =>
                                                    convertDateTime({
                                                        date: data?.JoiningDate,
                                                        customFormat: dateTimeFormat.appDateFormat
                                                    })
                                            },
                                            {
                                                title: USER_COLUMNS.TIME_SPENT,
                                                isSortable: true,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[USER_COLUMNS.TIME_SPENT],
                                                render: (data) => data?.TimeSpent
                                            },
                                            {
                                                title: USER_COLUMNS.TOTAL_USAGE_TIME,
                                                render: (data) =>
                                                    getHourFormatFromMilliSeconds(
                                                        data?.TotalApplicationUsageTimeMS
                                                    )
                                            },
                                            {
                                                title: USER_COLUMNS.POPULAR_USAGE_TIME,
                                                render: (data) => renderPopularUsageTime(data)
                                            },
                                            {
                                                title: USER_COLUMNS.USER_TYPE,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[USER_COLUMNS.USER_TYPE],
                                                isSortable: true,
                                                showAlphabetIcon: true,
                                                render: (data) => (
                                                    <CustomBadge
                                                        color={UserSubscriptionType.getLabelClass(
                                                            data.UserType
                                                        )}
                                                        classes={UserSubscriptionType.getLabelClass(
                                                            data.UserType
                                                        )}
                                                    >
                                                        {props.t(
                                                            UserSubscriptionType.getDisplayTextKey(
                                                                data.UserType
                                                            )
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: USER_COLUMNS.ACTIONS,
                                                render: (data, index) => (
                                                    <>
                                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                                            {data.Status ===
                                                            UserStatus.MATCH_READY ? (
                                                                <>
                                                                    {!data.WithGuardian ? (
                                                                        <li className="list-inline-item px-2">
                                                                            <div
                                                                                id={
                                                                                    "add_guardian" +
                                                                                    data.UserId
                                                                                }
                                                                                className="text-success cursor-pointer"
                                                                                onClick={() =>
                                                                                    toggleGuardianModal(
                                                                                        data.UserId
                                                                                    )
                                                                                }
                                                                            >
                                                                                <i className="bx bx-user-plus"></i>
                                                                                <UncontrolledTooltip
                                                                                    placement="top"
                                                                                    target={
                                                                                        "add_guardian" +
                                                                                        data.UserId
                                                                                    }
                                                                                >
                                                                                    {props.t(
                                                                                        "Invite Guardian"
                                                                                    )}
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                        </li>
                                                                    ) : (
                                                                        <li className="list-inline-item px-2">
                                                                            <Link
                                                                                id={
                                                                                    "guardian_exists" +
                                                                                    data.UserId
                                                                                }
                                                                                className="text-success"
                                                                                to="#"
                                                                            >
                                                                                <i className="bx bx-user-check"></i>
                                                                                <UncontrolledTooltip
                                                                                    placement="top"
                                                                                    target={
                                                                                        "guardian_exists" +
                                                                                        data.UserId
                                                                                    }
                                                                                >
                                                                                    {props.t(
                                                                                        "Guardian Exists"
                                                                                    )}
                                                                                </UncontrolledTooltip>
                                                                            </Link>
                                                                        </li>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <li className="list-inline-item px-2">
                                                                    <Link
                                                                        id={
                                                                            "not_ready" +
                                                                            data.UserId
                                                                        }
                                                                        className="text-warning"
                                                                        to="#"
                                                                    >
                                                                        <i className="bx bx-block"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "not_ready" +
                                                                                data.UserId
                                                                            }
                                                                        >
                                                                            {props.t(
                                                                                "Not Match Ready"
                                                                            )}
                                                                        </UncontrolledTooltip>
                                                                    </Link>
                                                                </li>
                                                            )}

                                                            {/* <li className="list-inline-item px-2">
                                                                <Link
                                                                    id={"edit" + data.UserId}
                                                                    className="text-primary"
                                                                    to={getRoute(
                                                                        APP_ROUTES.UPDATE_USER,
                                                                        {
                                                                            type:
                                                                                USER_ROUTE[
                                                                                    UserRoles.USER
                                                                                ],
                                                                            id: data.UserId
                                                                        }
                                                                    )}
                                                                >
                                                                    <i className="mdi mdi-pencil"></i>
                                                                    <UncontrolledTooltip
                                                                        placement="top"
                                                                        target={
                                                                            "edit" + data.UserId
                                                                        }
                                                                    >
                                                                        {props.t("Edit")}
                                                                    </UncontrolledTooltip>
                                                                </Link>
                                                            </li> */}

                                                            {data.Status === UserStatus.SUSPENDED ||
                                                            data.Status === UserStatus.DELETED ||
                                                            data.Status === UserStatus.REMOVED ||
                                                            data.Status ===
                                                                UserStatus.MANUAL_DELETED ? (
                                                                <li className="list-inline-item px-2">
                                                                    <div
                                                                        id={"restore" + data.UserId}
                                                                        className="text-success cursor-pointer"
                                                                        onClick={() =>
                                                                            toggleUserRestoreAlert(
                                                                                data.UserId
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="mdi mdi-lock-open-check"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "restore" +
                                                                                data.UserId
                                                                            }
                                                                        >
                                                                            {props.t("Restore")}
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                </li>
                                                            ) : (
                                                                <li className="list-inline-item px-2">
                                                                    <div
                                                                        id={"delete" + data.UserId}
                                                                        className="text-danger cursor-pointer"
                                                                        onClick={() =>
                                                                            toggleUserDeleteAlert(
                                                                                data.UserId
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="mdi mdi-trash-can"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "delete" +
                                                                                data.UserId
                                                                            }
                                                                        >
                                                                            {props.t("Delete")}
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                </li>
                                                            )}

                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="btn-sm btn-rounded"
                                                                onClick={() =>
                                                                    history.push(
                                                                        getRoute(
                                                                            APP_ROUTES.USER_DETAILS,
                                                                            {
                                                                                id: data.UserId
                                                                            }
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                {props.t("View Details")}
                                                            </Button>
                                                        </ul>
                                                    </>
                                                )
                                            }
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={totalUsers}
                                        onPageClick={handlePageClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <InvitationModal
                    isOpen={guardianModal}
                    closeModal={() => setGuardianModal(false)}
                    userId={selectedUserId}
                    roleType={UserRoles.USER}
                />
                <FiltersModal
                    filtersModal={filtersModal}
                    setFiltersModal={setFiltersModal}
                    filterConstants={UserStatus}
                    onFilterSelect={handleFilterChange}
                    currentFilters={statusFilters}
                />
                {showAlert ? (
                    <SweetAlert
                        title={
                            isLoading
                                ? props.t("Processing")
                                : showAlert === ALERT_MODES.DELETE
                                ? props.t("Delete")
                                : props.t("Restore")
                        }
                        description={
                            showAlert === ALERT_MODES.DELETE
                                ? props.t("Are you sure want to delete this user ?")
                                : props.t("Are you sure want to restore this user ?")
                        }
                        type={isLoading ? null : "warning"}
                        showCancel={!isLoading}
                        showConfirm={!isLoading}
                        onConfirm={() =>
                            showAlert === ALERT_MODES.DELETE
                                ? handleUserDelete()
                                : handleUserRestore()
                        }
                        onCancel={() => setShowAlert(false)}
                        success={isLoading ? null : true}
                        closeOnClickOutside={!isLoading}
                        isLoading={isLoading}
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = ({ users }) => {
    return {
        data: users.list,
        fetching: users.fetching,
        totalUsers: users.totalUsers
    };
};

const mapDispatchToProps = {
    getUserListing,
    removeUser,
    userRestore
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
