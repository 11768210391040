import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardBody, UncontrolledTooltip, Modal } from "reactstrap";

import "./Guardians.scss";
import { DataTable } from "../../components/DataTable";
import { Pagination } from "../../components/Pagination";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { SweetAlert } from "../../components/Alert";
import { CustomBadge } from "../../components/Badge";
import { SearchHeader } from "../../components/SearchHeader";
import { getGuardianListing, removeUser, guardianRestore } from "../../store/actions";
import { UserStatus } from "../../constants";
import { dateTimeFormat, convertDateTime } from "../../utils/dateFormatUtils";
import { toaster } from "../../utils/toasterUtil";
import { InvitationModal } from "../../components/InvitationModal";
import { UserRoleTypes, SortOrder } from "../../constants";

const GUARDIAN_COLUMNS = {
    FULL_NAME: "Full Name",
    EMAIL: "Email",
    GUARDIAN_OF: "Guardian Of",
    STATUS: "Status",
    JOINING_DATE: "Joining Date",
    ACTIONS: "Actions"
};

const SORTABLE_COLUMNS = {
    [GUARDIAN_COLUMNS.FULL_NAME]: "FullName",
    [GUARDIAN_COLUMNS.EMAIL]: "Email",
    [GUARDIAN_COLUMNS.JOINING_DATE]: "CreatedAt",
    [GUARDIAN_COLUMNS.STATUS]: "Status"
};

const ALERT_MODES = {
    DELETE: "DELETE",
    RESTORE: "RESTORE"
};

const Guardians = (props) => {
    const {
        getGuardianListing,
        fetching,
        totalGuardians,
        list,
        guardianRestore,
        removeUser
    } = props;

    const [page, setPage] = useState(1);
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [usersListModal, setUsersListModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [guardianModal, setGuardianModal] = useState(false);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [currentSortOrder, setCurrentSortOrder] = useState(null);

    const guardianListAbortController = useRef(null);

    useEffect(() => {
        guardianListAbortController.current = new AbortController();

        getGuardianListing(
            {
                Page: page,
                ...(searchText && { Q: searchText }),
                ...(currentSortColumn &&
                    currentSortOrder && {
                        Column: currentSortColumn,
                        Direction: currentSortOrder
                    })
            },
            guardianListAbortController.current.signal
        );

        return () => {
            if (guardianListAbortController.current.signal) {
                guardianListAbortController.current.abort();
            }
        };

        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [page, searchText, currentSortColumn, currentSortOrder, getGuardianListing]);

    const handleSortClick = (data) => {
        let sortOrder;
        if (!currentSortOrder || currentSortOrder === SortOrder.DESC) {
            sortOrder = SortOrder.ASC;
        } else {
            sortOrder = SortOrder.DESC;
        }
        setCurrentSortColumn(SORTABLE_COLUMNS[data.title]);
        setCurrentSortOrder(sortOrder);
    };

    const handleSearchChange = (e) => {
        setPage(1);
        setSearchText(e.target.value);
    };

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };

    const toggleUserDeleteAlert = (id) => {
        setSelectedUserId(id);
        setShowAlert(ALERT_MODES.DELETE);
    };

    const toggleUserRestoreAlert = (id) => {
        setSelectedUserId(id);
        setShowAlert(ALERT_MODES.RESTORE);
    };

    const handleUserDelete = () => {
        setIsLoading(true);

        let payload = {
            UserId: selectedUserId
        };

        removeUser(payload)
            .then((res) => {
                toaster({ title: props.t(res.Message), type: "success" });
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
                setShowAlert(false);
                setSelectedUserId("");
            });
    };

    const handleUserRestore = () => {
        let payload = {
            UserId: selectedUserId
        };

        setIsLoading(true);

        guardianRestore(payload)
            .then((res) => {
                toaster({ title: props.t(res.Message), type: "success" });
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
                setShowAlert(false);
                setSelectedUserId("");
            });
    };

    const renderUsersOfGuardians = (users) => {
        let cloneList;
        if (users && !!users.length) {
            cloneList = [...users].splice(0, 3);

            return cloneList.map((user) => (
                <CustomBadge color="warning" classes="warning guardian-of-badge">
                    {user.Email}
                </CustomBadge>
            ));
        }

        return null;
    };

    const viewGuardianUsers = (data) => {
        setUsers(data[0]);
        setUsersListModal(true);
    };

    return (
        <React.Fragment>
            <div className="page-content guardians">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Dashboard")}
                        breadcrumbItem={props.t("Guardians")}
                    />
                    <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <SearchHeader
                                        buttonText="Invite A Guardian"
                                        onButtonClick={() => setGuardianModal(true)}
                                        value={searchText}
                                        onChange={handleSearchChange}
                                    />
                                    <DataTable
                                        data={list}
                                        loading={fetching}
                                        currentSortOrder={currentSortOrder}
                                        currentSortColumn={currentSortColumn}
                                        onSortClick={handleSortClick}
                                        config={[
                                            {
                                                title: GUARDIAN_COLUMNS.FULL_NAME,
                                                render: (data) => data?.FullName || "-",
                                                sortColumn:
                                                    SORTABLE_COLUMNS[GUARDIAN_COLUMNS.FULL_NAME],
                                                isSortable: true,
                                                showAlphabetIcon: true
                                            },
                                            {
                                                title: GUARDIAN_COLUMNS.EMAIL,
                                                render: (data) => data?.Email || "-",
                                                sortColumn:
                                                    SORTABLE_COLUMNS[GUARDIAN_COLUMNS.EMAIL],
                                                isSortable: true,
                                                showAlphabetIcon: true
                                            },
                                            {
                                                title: GUARDIAN_COLUMNS.GUARDIAN_OF,
                                                withWrap: true,
                                                render: (data) => {
                                                    return data?.Users?.length ? (
                                                        <div>
                                                            {renderUsersOfGuardians(data.Users)}

                                                            {data?.Users.length > 3 && (
                                                                <li className="list-inline-item px-2">
                                                                    <div
                                                                        id={"display" + data.UserId}
                                                                        className="text-success cursor-pointer"
                                                                        onClick={() =>
                                                                            viewGuardianUsers([
                                                                                data.Users
                                                                            ])
                                                                        }
                                                                    >
                                                                        <i className="bx bx-group"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "display" +
                                                                                data.UserId
                                                                            }
                                                                        >
                                                                            {props.t("View Users")}
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    );
                                                }
                                            },
                                            {
                                                title: GUARDIAN_COLUMNS.STATUS,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[GUARDIAN_COLUMNS.STATUS],
                                                isSortable: true,
                                                showAlphabetIcon: true,
                                                render: (data) => (
                                                    <CustomBadge
                                                        color={UserStatus.getLabelClass(
                                                            data.Status
                                                        )}
                                                        classes={UserStatus.getLabelClass(
                                                            data.Status
                                                        )}
                                                    >
                                                        {props.t(
                                                            UserStatus.getDisplayTextKey(
                                                                data.Status
                                                            )
                                                        )}
                                                    </CustomBadge>
                                                )
                                            },
                                            {
                                                title: GUARDIAN_COLUMNS.JOINING_DATE,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[GUARDIAN_COLUMNS.JOINING_DATE],
                                                isSortable: true,
                                                render: (data) =>
                                                    convertDateTime({
                                                        date: data?.JoiningDate,
                                                        customFormat: dateTimeFormat.appDateFormat
                                                    })
                                            },
                                            {
                                                title: GUARDIAN_COLUMNS.ACTIONS,
                                                render: (data, index) => (
                                                    <>
                                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                                            {data.Status === UserStatus.SUSPENDED ||
                                                            data.Status === UserStatus.DELETED ||
                                                            data.Status === UserStatus.REMOVED ||
                                                            data.Status ===
                                                                UserStatus.MANUAL_DELETED ? (
                                                                <li className="list-inline-item px-2">
                                                                    <div
                                                                        id={"restore" + data.UserId}
                                                                        className="text-success cursor-pointer"
                                                                        onClick={() =>
                                                                            toggleUserRestoreAlert(
                                                                                data.UserId
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="mdi mdi-lock-open-check"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "restore" +
                                                                                data.UserId
                                                                            }
                                                                        >
                                                                            {props.t("Restore")}
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                </li>
                                                            ) : (
                                                                <li className="list-inline-item px-2">
                                                                    <div
                                                                        id={"delete" + data.UserId}
                                                                        className="text-danger cursor-pointer"
                                                                        onClick={() =>
                                                                            toggleUserDeleteAlert(
                                                                                data.UserId
                                                                            )
                                                                        }
                                                                    >
                                                                        <i className="mdi mdi-trash-can"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={
                                                                                "delete" +
                                                                                data.UserId
                                                                            }
                                                                        >
                                                                            {props.t("Delete")}
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </>
                                                )
                                            }
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={totalGuardians}
                                        onPageClick={handlePageClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {showAlert ? (
                    <SweetAlert
                        title={
                            isLoading
                                ? props.t("Processing")
                                : showAlert === ALERT_MODES.DELETE
                                ? props.t("Delete")
                                : props.t("Restore")
                        }
                        description={
                            showAlert === ALERT_MODES.DELETE
                                ? props.t("Are you sure want to delete this Guardian ?")
                                : props.t("Are you sure want to restore this Guardian ?")
                        }
                        type={isLoading ? null : "warning"}
                        showCancel={!isLoading}
                        showConfirm={!isLoading}
                        onConfirm={() =>
                            showAlert === ALERT_MODES.DELETE
                                ? handleUserDelete()
                                : handleUserRestore()
                        }
                        onCancel={() => setShowAlert(false)}
                        success={isLoading ? null : true}
                        closeOnClickOutside={!isLoading}
                        isLoading={isLoading}
                    />
                ) : null}

                {guardianModal && (
                    <InvitationModal
                        isOpen={guardianModal}
                        closeModal={() => setGuardianModal(false)}
                        roleType={UserRoleTypes.GUARDIAN}
                    />
                )}
                <Modal size="sm" isOpen={usersListModal}>
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            Users
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setUsersListModal(false)}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h6>Following are the users associated with this guardian :</h6>

                        {users.map((user, index) => {
                            return (
                                <p key={index}>
                                    {index + 1}. {user.FullName}{" "}
                                    <span className="guardian-email">({user.Email})</span>
                                </p>
                            );
                        })}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                            onClick={() => setUsersListModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        list: state.users.list,
        fetching: state.users.fetching,
        totalGuardians: state.users.totalUsers
    };
};

const mapDispatchToProps = {
    getGuardianListing,
    removeUser,
    guardianRestore
};

export default connect(mapStateToProps, mapDispatchToProps)(Guardians);
