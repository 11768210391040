import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

import "./DataTable.scss";

import { getCountryListing, COUNTRY_PARAMS } from "../../store/actions";

const FilterCell = (props) => {
    const { options, t, getCountryListing } = props;
    const { title, filterValue, onFilterSelect, scrollFilter = false } = options;

    const [open, setOpen] = useState(false);
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        getCountryListing({
            TransactionExistence: COUNTRY_PARAMS.TRANSACTION_EXISTANCE
        })
            .then((res) => {
                setCountries(res.Data);
            })
            .catch((err) => {});
    }, []);

    const handleMenuToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleItemSelect = (value) => {
        onFilterSelect(value);
    };

    return (
        <Dropdown isOpen={open} toggle={handleMenuToggle}>
            <DropdownToggle tag="div" className="text-muted data-table-head sortable ">
                {title}
                {filterValue && Boolean(countries.length) && (
                    <span className="filter-value">
                        {countries.find((item) => item?.Id === filterValue)["Name"]}
                    </span>
                )}
                <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>
            <DropdownMenu className={scrollFilter ? `scroll-dropdown` : ""}>
                <DropdownItem onClick={() => handleItemSelect(null)} active={!filterValue}>
                    {t("All")}
                </DropdownItem>
                {countries.map((item, index) => {
                    return (
                        <DropdownItem
                            key={index}
                            onClick={() => handleItemSelect(item?.Id)}
                            active={filterValue === item?.Id}
                        >
                            {item?.Name}
                        </DropdownItem>
                    );
                })}
            </DropdownMenu>
        </Dropdown>
    );
};

const mapDispatchToProps = {
    getCountryListing
};

export default withNamespaces()(connect(null, mapDispatchToProps)(FilterCell));
