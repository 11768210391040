import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import "./DropdownButton.scss";

import { FilterButton } from "../FilterButton";

const DropdownButton = (props) => {
    const {
        handleCSV = () => {},
        handlePDF = () => {},
        toggleOpen = false,
        handleToggle = () => {}
    } = props;

    return (
        <Dropdown isOpen={toggleOpen} toggle={handleToggle} direction="left">
            <DropdownToggle tag="div">
                <FilterButton text="Export" classes="font-weight-bold" />
            </DropdownToggle>
            <DropdownMenu>
                <div className="btn-container">
                    <p onClick={handleCSV}>Download CSV</p>
                    <p onClick={handlePDF}> Download PDF</p>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default DropdownButton;
