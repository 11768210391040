import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class SplineChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [
                {
                    name: props.graph1Title,
                    data: props.data
                },
                {
                    name: props.graph2Title,
                    data: []
                }
            ],
            options: {
                chart: {
                    toolbar: {
                        show: false
                    },
                    height: 350,
                    type: "line",
                    stacked: false
                },
                dataLabels: {
                    enabled: false
                },
                colors: ["#FF1654", "#247BA0"],
                stroke: {
                    width: [4, 4]
                },
                xaxis: {
                    type: "month",
                    categories: props.categories
                },
                yaxis: [
                    {
                        labels: {
                            formatter: function (val) {
                                return val?.toFixed(0);
                            }
                        }
                    }
                ]
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data || prevProps.data2 !== this.props.data2) {
            this.setState((prev) => ({
                series: prev.series.map((item) => {
                    if (item.name === this.props.graph1Title) {
                        return {
                            ...item,
                            data: this.props.data
                        };
                    }
                    if (this.props.data2 && item.name === this.props.graph2Title) {
                        if (item.data) {
                            return {
                                ...item,
                                data: this.props.data2
                            };
                        }
                    } else {
                        return {
                            ...item,
                            data: []
                        };
                    }
                    return item;
                })
            }));
        }
        if (prevProps.categories !== this.props.categories) {
            this.setState((prev) => ({
                options: {
                    ...prev,
                    xaxis: {
                        ...prev.xaxis,
                        categories: this.props.categories
                    }
                }
            }));
        }
    }
    render() {
        return (
            <React.Fragment>
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="line"
                    height="350"
                />
            </React.Fragment>
        );
    }
}

export default SplineChart;
