import React from "react";
import { Collapse } from "reactstrap";

import "./OptionFilter.scss";

import { FilterButton } from "../../components/FilterButton";
import { getKeyByValue } from "../../utils/commonUtils";

const OptionFilter = (props) => {
    const {
        title,
        isOpen,
        filterConstants = {},
        selectedFilterValue,
        onChange,
        filterValues = null,
        customButton = null,
        required = false,
        excludeFilters = [],
        constantFilters = []
    } = props;

    const getFiltersToExclude = () => {
        return excludeFilters.map((item) => getKeyByValue(filterConstants, item));
    };

    const handleFilterClick = (value) => {
        if (onChange && typeof onChange === "function") {
            if (value === selectedFilterValue && !required) {
                onChange(null);
            } else {
                onChange(value);
            }
        }
    };

    const renderButton = () => {
        return customButton ? (
            customButton(
                filterConstants.getDisplayTextKey(selectedFilterValue),
                filterValues?.[selectedFilterValue]
            )
        ) : title ? (
            <div className="d-flex mb-4">
                <FilterButton
                    text={`${title}: ${filterConstants.getDisplayTextKey(selectedFilterValue)}`}
                />
            </div>
        ) : null;
    };

    return (
        <div className="mb-3">
            {Boolean(constantFilters) && Boolean(constantFilters.length) ? null : renderButton()}

            <Collapse isOpen={isOpen}>
                {Object.keys(filterConstants)
                    .filter((item) => !getFiltersToExclude().includes(item))
                    .map((key, index) => {
                        return (
                            <div
                                className="d-flex"
                                key={index}
                                onClick={() => handleFilterClick(filterConstants[key])}
                            >
                                <div
                                    className={`checkbox-container ${
                                        selectedFilterValue === filterConstants[key]
                                            ? "selected-checkbox"
                                            : ""
                                    }`}
                                ></div>
                                <div className="d-flex justify-content-between w-100">
                                    <p className="ml-2 font-weight-bold mb-1">
                                        {filterConstants.getDisplayTextKey(filterConstants[key])}
                                    </p>
                                    {filterValues && (
                                        <p className="font-weight-bold mb-1">
                                            {filterValues[filterConstants[key]]}
                                        </p>
                                    )}
                                </div>
                            </div>
                        );
                    })}

                {Boolean(constantFilters) && Boolean(constantFilters.length) && (
                    <div className="margin-top-52">
                        {constantFilters.map((item) => {
                            return (
                                <div className="d-flex ">
                                    <div className={`checkbox-container wh-checkbox-10 ` + item.class}></div>
                                    <div className="d-flex justify-content-between w-100">
                                        <p className="ml-2 font-weight-bold mb-1">{item.key}</p>

                                        <p className="font-weight-bold mb-1">{item.value}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </Collapse>
        </div>
    );
};

export default OptionFilter;
