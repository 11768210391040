export const downloadCSVData = (csvText) => {
    let hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csvText);
    hiddenElement.target = "_blank";
    hiddenElement.download = `report_${new Date().getTime()}.csv`;
    hiddenElement.click();
};

export const downloadPDFData = (response) => {
    const file = new Blob([response], {
        type: "application/pdf"
    });

    const fileURL = URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", `report_${new Date().getTime()}.pdf`);

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};
