import { injectBaseConstantMethods } from "./BaseConstants";

const COUNTRIES = {
    PAKISTAN: 1,
    SCOTLAND: 2,
    ENGLAND: 3,
    AMERICA: 5,
    AUSTRALIA: 6,
    BANGLADESH: 7,
    GERMANY: 8,
    CANADA: 11,
    AFGHANISTAN: 12,
    ALBANIA: 14,
    ALGERIA: 15,
    AMERICAN_SAMOA: 16,
    ANDORRA: 17,
    ANGOLA: 18,
    ANGUILLA: 19,
    ANTARCTICA: 20,
    ANTIGUA_AND_BARBUDA: 21,
    ARGENTINA: 22,
    ARMENIA: 23,
    ARUBA: 24,
    AZERBAIJAN: 26,
    BAHRAIN: 28,
    BELGIUM: 31,
    BHUTAN: 35,
    BONAIRE_SINT_EUSTATIUS_AND_SABA: 37,
    BOTSWANA: 39,
    BRAZIL: 41,
    COCOAS_KEELING_ISLANDS: 56,
    GUINEA: 100
};
const displayTextKeys = {
    [COUNTRIES.PAKISTAN]: "Pakistan",
    [COUNTRIES.SCOTLAND]: "Scotland",
    [COUNTRIES.ENGLAND]: "England",
    [COUNTRIES.AMERICA]: "America",
    [COUNTRIES.AUSTRALIA]: "Australia",
    [COUNTRIES.BANGLADESH]: "Bangladesh",
    [COUNTRIES.GERMANY]: "Germany",
    [COUNTRIES.CANADA]: "Canada",
    [COUNTRIES.AFGHANISTAN]: "Afghanistan",
    [COUNTRIES.ALBANIA]: "Albania",
    [COUNTRIES.ALGERIA]: "Algeria",
    [COUNTRIES.AMERICAN_SAMOA]: "American Samoa",
    [COUNTRIES.ANDORRA]: "Andorra",
    [COUNTRIES.ANGOLA]: "Angola",
    [COUNTRIES.ANGUILLA]: "Anguilla",
    [COUNTRIES.ANTARCTICA]: "Antarctica",
    [COUNTRIES.ANTIGUA_AND_BARBUDA]: "Antigua and Barbuda",
    [COUNTRIES.ARGENTINA]: "Argentina",
    [COUNTRIES.ARMENIA]: "Armenia",
    [COUNTRIES.ARUBA]: "Aruba",
    [COUNTRIES.AZERBAIJAN]: "Azerbaijan",
    [COUNTRIES.BAHRAIN]: "Bahrain",
    [COUNTRIES.BELGIUM]: "Belgium",
    [COUNTRIES.BHUTAN]: "Bhutan",
    [COUNTRIES.BONAIRE_SINT_EUSTATIUS_AND_SABA]: "Bonaire, Sint Eustatius and Saba",
    [COUNTRIES.BOTSWANA]: "Botswana",
    [COUNTRIES.BRAZIL]: "Brazil",
    [COUNTRIES.COCOAS_KEELING_ISLANDS]: "Cocos (Keeling) Islands",
    [COUNTRIES.GUINEA]: "Guinea"
};

const labelClass = {};

export default injectBaseConstantMethods(COUNTRIES, displayTextKeys, labelClass);
