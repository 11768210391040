import { injectBaseConstantMethods } from "./BaseConstants";

export const DeleteAnalyticsConstants = {
    ADMIN_DELETED: 1,
    USER_DELETED: 2,
    FOUND_FUTURE_PARTNER: 3,
    MATCH_NOT_FOUND: 4,
    EXPECTING_DIFFERENT: 5,
    UNLIKE_PLATFORM: 6,
    OTHER: 7,
    NONE: 8
};

const displayTextKeys = {
    [DeleteAnalyticsConstants.ADMIN_DELETED]: "Admin",
    [DeleteAnalyticsConstants.USER_DELETED]: "User",
    [DeleteAnalyticsConstants.FOUND_FUTURE_PARTNER]: "Found Future Partner",
    [DeleteAnalyticsConstants.MATCH_NOT_FOUND]: "Match Not Found",
    [DeleteAnalyticsConstants.EXPECTING_DIFFERENT]: "Expecting Different",
    [DeleteAnalyticsConstants.UNLIKE_PLATFORM]: "Unlike Platform",
    [DeleteAnalyticsConstants.OTHER]: "Other",
    [DeleteAnalyticsConstants.NONE]: "None"
};

const labelClass = {
    [DeleteAnalyticsConstants.ADMIN_DELETED]: "",
    [DeleteAnalyticsConstants.USER_DELETED]: "",
    [DeleteAnalyticsConstants.FOUND_FUTURE_PARTNER]: "",
    [DeleteAnalyticsConstants.MATCH_NOT_FOUND]: "",
    [DeleteAnalyticsConstants.EXPECTING_DIFFERENT]: "",
    [DeleteAnalyticsConstants.UNLIKE_PLATFORM]: "",
    [DeleteAnalyticsConstants.OTHER]: "",
    [DeleteAnalyticsConstants.NONE]: ""
};

export default injectBaseConstantMethods(DeleteAnalyticsConstants, displayTextKeys, labelClass);
