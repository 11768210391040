import { DashboardAnalyticsTypes } from "../../constants";
import { ANALYTICS_DATA_REQUEST, ANALYTICS_DATA_SUCCESS, ANALYTICS_DATA_FAIL } from "../actions";

const initialState = {
    [DashboardAnalyticsTypes.USERS]: {
        fetching: false
    },
    [DashboardAnalyticsTypes.CUSTOMERS]: {
        fetching: false
    },
    [DashboardAnalyticsTypes.INQUERIES]: {
        fetching: false
    },
    [DashboardAnalyticsTypes.MATCHES]: {
        fetching: false
    },
    [DashboardAnalyticsTypes.REPORTS]: {
        fetching: false
    },
    [DashboardAnalyticsTypes.REVENUE]: {
        fetching: false
    },
    [DashboardAnalyticsTypes.DELETED_USERS]: {
        fetching: false
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ANALYTICS_DATA_REQUEST:
            return {
                ...state,
                [action.analyticsType]: {
                    ...state[action.analyticsType],
                    fetching: true
                }
            };
        case ANALYTICS_DATA_SUCCESS:
            return {
                ...state,
                [action.analyticsType]: {
                    ...state[action.analyticsType],
                    fetching: false,
                    ...action.payload
                }
            };
        case ANALYTICS_DATA_FAIL:
            return {
                ...state,
                [action.analyticsType]: {
                    ...state[action.analyticsType],
                    fetching: false
                }
            };
        default:
            return state;
    }
};
