import React, { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useParams, useHistory } from "react-router-dom";
import parsePhoneNumber from "libphonenumber-js";

import { toaster } from "../../utils/toasterUtil";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Button } from "../../components/Button";
import { createUser, getUserById, updateUser } from "../../store/actions";
import UserRoles from "../../constants/UserRoleTypes";

const USER_ROLES_LABELS = {
    [UserRoles.USER]: "User",
    [UserRoles.GUARDIAN]: "Guardian",
    [UserRoles.MATCH_MAKER]: "Match Maker"
};

const ROUTE_TYPES = {
    user: UserRoles.USER,
    matchmaker: UserRoles.MATCH_MAKER,
    guardian: UserRoles.GUARDIAN
};

const CreateUser = (props) => {
    const { createUser, updateUser, currentUser, getUserById } = props;
    console.log(props);

    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const { type, id } = useParams();
    const history = useHistory();

    const isEdit = useMemo(() => {
        return Boolean(id);
    }, [id]);

    useEffect(() => {
        if (isEdit) {
            if (currentUser) {
                setPhone(`${currentUser?.Phone?.Extension + currentUser?.Phone?.Number}`);
                setName(currentUser.FullName);
                setEmail(currentUser.Email);
            } else {
                getUserById(id)
                    .then((res) => {
                        setPhone(`${res.Data?.Phone?.Extension + res.Data?.Phone?.Number}`);
                        setName(res.Data.Name);
                        setEmail(res.Data.Email);
                    })
                    .catch((err) => {});
            }
        }

        //es-lint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, id, isEdit, getUserById]);

    const canSubmit = () => {
        return email?.trim().length && name?.trim().length && phone?.trim().length;
    };

    const handleRedirect = () => {
        if (parseInt(ROUTE_TYPES[type]) === UserRoles.USER) {
            history.push(getRoute(APP_ROUTES.USERS_LISTING));
        }
    };

    const handleSubmit = () => {
        if (!canSubmit()) {
            return;
        }

        let phoneNumber = parsePhoneNumber(phone);
        let validPhone = phoneNumber ? phoneNumber.isValid() : false;
        if (!validPhone) {
            toaster({ title: "Phone number is not valid", type: "danger" });
            return;
        }
        setIsLoading(true);

        let payload = {
            FullName: name,
            Email: email,
            PhoneNumber: phoneNumber?.nationalNumber,
            PhoneExtension: "+" + phoneNumber?.countryCallingCode,
            Type: ROUTE_TYPES[type]
        };

        const handleUserAction = isEdit ? updateUser(payload, id) : createUser(payload);

        handleUserAction
            .then((res) => {
                toaster({ title: res.Message, type: "success" });
                handleRedirect();
            })
            .catch((err) => {})
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getTitle = () => {
        return isEdit
            ? `Update 
        ${USER_ROLES_LABELS[ROUTE_TYPES[type]]}`
            : `Add 
        ${USER_ROLES_LABELS[ROUTE_TYPES[type]]}`;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        withBackButton
                        titleLink={getRoute(APP_ROUTES.USERS_LISTING)}
                        title="Users"
                        breadcrumbItem={getTitle()}
                    />
                    <Row className="d-flex justify-content-center">
                        <Col xs="8">
                            <Card className="mt-5">
                                <CardBody>
                                    <div className="d-flex justify-content-center mb-4">
                                        <h3>{getTitle()}</h3>
                                    </div>

                                    <AvForm
                                        className="form-horizontal "
                                        onValidSubmit={handleSubmit}
                                    >
                                        <Row className="my-0">
                                            <Col md="4">
                                                <AvField
                                                    name="Name"
                                                    type="text"
                                                    label="Name"
                                                    value={name}
                                                    placeholder="Enter Name"
                                                    onChange={(event) =>
                                                        setName(event.target.value)
                                                    }
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Name is required."
                                                        },
                                                        maxLength: {
                                                            value: 55,
                                                            errorMessage:
                                                                "Please enter a valid name, e.g John Smith (Min 6 and Max 55 characters allowed. Special characters are not allowed"
                                                        },
                                                        minLength: {
                                                            value: 6,
                                                            errorMessage:
                                                                "Please enter a valid name, e.g John Smith (Min 6 and Max 55 characters allowed. Special characters are not allowed"
                                                        },
                                                        pattern: {
                                                            value: "/^[a-zA-Z ]+$/",
                                                            errorMessage:
                                                                "Please enter a valid name, e.g John Smith (Min 6 and Max 55 characters allowed. Special characters are not allowed"
                                                        }
                                                    }}
                                                    required
                                                />
                                            </Col>
                                            <Col md="4">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    type="email"
                                                    value={email}
                                                    placeholder="Enter Email"
                                                    onChange={(event) =>
                                                        setEmail(event.target.value)
                                                    }
                                                    errorMessage="Please enter a valid email ID"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "Email is required."
                                                        },
                                                        maxLength: {
                                                            value: 128,
                                                            errorMessage:
                                                                "Max 128 characters are allowed"
                                                        }
                                                    }}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="my-0">
                                            <Col md="4">
                                                <AvField
                                                    type="text"
                                                    name="phoneNumber"
                                                    label="Phone Number"
                                                    value={phone}
                                                    onChange={(event) =>
                                                        setPhone(event.target.value)
                                                    }
                                                    placeholder="Enter Phone Number"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage:
                                                                "Phone Number is required."
                                                        },
                                                        minLength: {
                                                            value: 10,
                                                            errorMessage:
                                                                "Please enter a valid phone number"
                                                        },
                                                        maxLength: {
                                                            value: 25,
                                                            errorMessage:
                                                                "Max 128 characters are allowed"
                                                        },
                                                        pattern: {
                                                            value: "/^[+][0-9]+$/",
                                                            errorMessage:
                                                                "Please enter a valid number e.g +92"
                                                        }
                                                    }}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <div className="d-flex justify-content-end">
                                            <Button
                                                btnText={isEdit ? "Update" : "Add"}
                                                type="submit"
                                                isLoading={isLoading}
                                                disabled={isLoading || !canSubmit()}
                                            />
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapDispatchToProps = {
    createUser,
    updateUser,
    getUserById
};

const mapStateToProps = (state, ownProps) => {
    const id = parseInt(ownProps.match.params.id);
    let currentUser;

    if (id) {
        currentUser = state.users.list.find((user) => {
            return user.UserId === id;
        });
    }

    return {
        currentUser
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
