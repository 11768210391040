import React, { useState, useEffect, useRef } from "react";
import { withNamespaces } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";

import "./ReportListing.scss";

import StatusIndicator from "../../components/StatusIndicator/StatusIndicator";
import UserListingBadge from "../../components/UserListingBadge/UserListingBadge";
import { DataTable } from "../../components/DataTable";
import { Pagination } from "../../components/Pagination";
import { getReportsListing } from "../../store/actions";
import { SortOrder } from "../../constants";
import { getReportStatus, getCauseType } from "../../utils/appUtils";
import { convertDateTime, dateTimeFormat } from "../../utils/dateFormatUtil";
import BlockButton from "../../components/Button/Button";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { getMediaThumbPath } from "../../utils/commonUtils";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const REPORT_COLUMNS = {
    DATE: "Date",
    REPORTED_BY: "Reported by",
    CAUSE: "Cause",
    STATUS: "Status"
};

const SORTABLE_COLUMNS = {
    [REPORT_COLUMNS.DATE]: "Date",
    [REPORT_COLUMNS.REPORTED_BY]: "FullName",
    [REPORT_COLUMNS.CAUSE]: "CauseId",
    [REPORT_COLUMNS.STATUS]: "Status"
};

const ReportListing = (props) => {
    const { fetching, data, count } = props;
    const [page, setPage] = useState(1);
    const [currentSortOrder, setCurrentSortOrder] = useState(SortOrder.DESC);
    const [currentSortColumn, setCurrentSortColumn] = useState(REPORT_COLUMNS.DATE);
    const history = useHistory();
    const reportsListAbortController = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        reportsListAbortController.current = new AbortController();
        dispatch(
            getReportsListing(
                {
                    Page: page,
                    ...(currentSortColumn &&
                        currentSortOrder && {
                            Column: currentSortColumn,
                            Direction: currentSortOrder
                        })
                },
                reportsListAbortController.current.signal
            )
        );
        return () => {
            if (reportsListAbortController.current) {
                reportsListAbortController.current.abort();
            }
        };
    }, [page, currentSortOrder, currentSortColumn, dispatch]);

    const handleRowClick = (e, data) => {
        if (data && data.ReportId) {
            history.push(getRoute(APP_ROUTES.REPORT_DETAILS, { id: data.ReportId }));
        }
    };

    const handleSortClick = (data) => {
        let sortOrder;
        if (!currentSortOrder || currentSortOrder === SortOrder.DESC) {
            sortOrder = SortOrder.ASC;
        } else {
            sortOrder = SortOrder.DESC;
        }
        setCurrentSortColumn(SORTABLE_COLUMNS[data.title]);
        setCurrentSortOrder(sortOrder);
    };

    const handlePageClick = (pageNo) => {
        setPage(pageNo);
    };

    const renderDate = (date) => {
        return convertDateTime({
            to: "LOCAL",
            dateOnly: true,
            date: date,
            customFormat: dateTimeFormat.appDateFormat
        });
    };

    const renderStatus = (status) => {
        const userStatus = getReportStatus(status);
        return <StatusIndicator color={userStatus.color} badgeTitle={userStatus.text} />;
    };

    const renderReportedBy = (reporter) => {
        return (
            <UserListingBadge
                src={getMediaThumbPath(reporter?.Media)}
                name={reporter.Name}
                className="border-none"
            />
        );
    };

    return (
        <>
            <div className="page-content hc-listing-table table-responsive">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Reports" />
                    <Row className="p-0 m-0">
                        <Col xs="12" className="p-0">
                            <Card>
                                <CardBody className="p-0">
                                    <DataTable
                                        data={data}
                                        loading={fetching}
                                        currentSortOrder={currentSortOrder}
                                        currentSortColumn={currentSortColumn}
                                        onSortClick={handleSortClick}
                                        config={[
                                            {
                                                title: REPORT_COLUMNS.DATE,
                                                isSortable: true,
                                                render: (data) => renderDate(data.Date),
                                                sortColumn: SORTABLE_COLUMNS[REPORT_COLUMNS.DATE]
                                            },
                                            {
                                                title: REPORT_COLUMNS.REPORTED_BY,
                                                render: (data) =>
                                                    renderReportedBy(data?.ReportedBy),
                                                isSortable: true,
                                                sortColumn:
                                                    SORTABLE_COLUMNS[REPORT_COLUMNS.REPORTED_BY],
                                                showAlphabetIcon: true
                                            },
                                            {
                                                title: REPORT_COLUMNS.CAUSE,
                                                isSortable: true,
                                                render: (data) => data && getCauseType(data?.Cause),
                                                sortColumn: SORTABLE_COLUMNS[REPORT_COLUMNS.CAUSE],
                                                showAlphabetIcon: true
                                            },
                                            {
                                                title: REPORT_COLUMNS.STATUS,
                                                isSortable: true,
                                                render: (data) => renderStatus(data?.Status),
                                                sortColumn: SORTABLE_COLUMNS[REPORT_COLUMNS.STATUS],
                                                showAlphabetIcon: true
                                            },
                                            {
                                                render: (data, index) => (
                                                    <BlockButton
                                                        className="btn button-type-2 btn-md"
                                                        onClick={(e) => {
                                                            handleRowClick(e, data);
                                                        }}
                                                    >
                                                        {props.t("Details")}
                                                    </BlockButton>
                                                )
                                            }
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={count}
                                        onPageClick={handlePageClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        fetching: state.report.fetching,
        count: state.report.count,
        data: state.report.list
    };
};

export default withNamespaces()(connect(mapStateToProps, null)(ReportListing));
